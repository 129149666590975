import React, {useCallback, useEffect, useMemo, useState} from "react";
import classNames from "classnames";
import {useAuthenticatedApi} from "@webng/react-app-common";
import {useTranslation} from "react-i18next";
import {WebEmbed} from "../webembed/WebEmbed";
import {OEmbed} from "../webembed/OEmbed";
import {IWebEmbedContentBlock} from "@webng-types/write-model";
import {ApiClient, AuthenticatedApiClient} from "@webng/tickaroo-api";
import { isRelativeUrl } from "../utils/regex";
import {Info} from "react-feather";

interface WebEmbedContentBlockViewProps {
  block: IWebEmbedContentBlock
  className?: string
  isPreview?: boolean
}

export interface UpdateOEmbedResult {
  requestedUrl: string
  error?: any
  oembed?: OEmbed
}


export function updateOEmbed(apiClient: AuthenticatedApiClient, url: string, callback: (result: UpdateOEmbedResult) => void) {
  return apiClient.getJson(ApiClient.API_WEB_EMBED, {url: url}).then(value => {
    callback({
      requestedUrl: url,
      oembed: value
    })
  }).catch(error => {
    callback({
      requestedUrl: url,
      error: error
    })
  })
}

export function WebEmbedContentBlockView({block, className, isPreview} : WebEmbedContentBlockViewProps) {
  const [loadedJson, setLoadedJson] = useState<OEmbed|undefined>(undefined)
  const apiClient = useAuthenticatedApi();
  const {i18n, t} = useTranslation("liveblog");

  const url = (typeof window !== "undefined") && window.location && isRelativeUrl(block.url) ? new URL(block.url, window.location.origin).toString() : block.url
  const showRelativeLinkHint = isRelativeUrl(block.url) && isPreview === true

  const json = useMemo(() => {
    if(loadedJson) {
      return loadedJson;
    } else if(block.oembed_json) {
      return JSON.parse(block.oembed_json)
    } else {
      return undefined;
    }
  }, [block.oembed_json, loadedJson]);

  const onOEmbedUpdated = useCallback((result: UpdateOEmbedResult) => {
    if(url === result.requestedUrl) {
      if(result.oembed) {
        setLoadedJson(result.oembed)
      }
    }
  }, [url, setLoadedJson])

  useEffect(() => {
    if((!json || url !== json._original) && !showRelativeLinkHint) {
      const promise = updateOEmbed(apiClient, url, onOEmbedUpdated)
      return () => promise.cancel()
    }
  }, [json, apiClient, url, onOEmbedUpdated, showRelativeLinkHint])

  if(json) {
    return <WebEmbed blockId={block.local_id!}
                     oembed={json}
                     locale={i18n.language}
                     className={classNames(`tik4-we tik4-we--${json.type} tik4-we--${json.provider_name?.toLowerCase()}`, className)}
                     data-tik4-we-url={url} />
  } else {
    if (showRelativeLinkHint) {
      return <div className={classNames("tik4-we", className)} data-tik4-we-url={block.url}>
        <div className="tik4-hint tik4-hint--sm tik4-hint--box tik4-we__relative-link-hint tik4-break-safe">
          <Info className="tik4-icon" />
          <div className="tik4-hint__content">
            <div className="tik4-hint__hl">
              {block.url}
            </div>
            {t("liveblog.webembed.relative_link_hint")}
          </div>
        </div>
      </div>
    } else {
      // return <div className={classNames("tik4-we", className)} data-tik4-we-url={block.url}>
      //   <a href={url} target="_blank" rel="noopener noreferrer" className="tik4-link">{block.url}</a>
      // </div>
      return <div className={classNames("tik4-we", className)} data-tik4-we-url={block.url} />
    }
  }

  // return <div className={classNames("tik4-we", className)} data-tik4-we-url={block.url} />
}
