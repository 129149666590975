import {ITag} from "@webng-types/write-model";
import React, {useCallback} from "react";
import {TagImage} from "./TagImage";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import { EventTagNavigationType } from "./Liveblog";
import loadable from "@loadable/component";
import {ChevronDown, X} from "react-feather";

const Select = loadable(() => import('react-select').then(module => module.default), {
  fallback: <div></div>
})

const components = {
  DropdownIndicator: loadable(() => import('react-select').then(module => ({ default: module.components.DropdownIndicator }))),
  ValueContainer: loadable(() => import('react-select').then(module => ({ default: module.components.ValueContainer }))),
  Placeholder: loadable(() => import('react-select').then(module => ({ default: module.components.Placeholder }))),
};

import {
  DropdownIndicatorProps,
  ValueContainerProps,
  PlaceholderProps
} from "react-select";

const FILTER_LIST_MAX_TAGS = 12;

export type OnTagClickHandler = (tag: ITag|undefined) => void

interface LiveblogTagFilterProps {
  tags: ITag[]
  onClick?: OnTagClickHandler
  selectedTag?: string
  eventTagNavigation: EventTagNavigationType
}

interface TagProps {
  tag: ITag
  selected?: boolean
  onClick?: OnTagClickHandler
}

function Tag({tag, onClick, selected}: TagProps) {

  const onClickCallback = useCallback(() => {
    onClick && onClick(tag)
  }, [onClick, tag])

  const className = classNames(
    `tik4-tag tik4-tag--button tik4-tag--x-${tag.slug || 'id-missing'}`,
    {
      'tik4-tag--selected': selected
    }
  )

  return <React.Fragment>
    <button className={className} onClick={onClickCallback} type="button">
      <TagImage image={tag.image} className="tik4-tag__img" />
      <div className="tik4-tag__name">{tag.name}</div>
    </button>
  </React.Fragment>
}


interface SearchFilterProps {
  tags: ITag[];
  onClick?: OnTagClickHandler;
  selectedTag?: string;
}

function SearchFilter({ tags, onClick, selectedTag }: SearchFilterProps) {
  const { t } = useTranslation("liveblog");


  const handleChange = (selectedOption: { value: string; label: string } | null) => {
    const selectedTag = selectedOption
    ? tags.find(tag => tag._id === selectedOption.value)
    : null;
    if (onClick) {
      onClick(selectedTag || undefined);
    }
  };

  const tagOptions = tags.map(tag => ({
    value: tag._id || '',
    label: tag.name,
    component: <div className="tik4-tfs__single-value__container"><TagImage image={tag.image} className="tik4-tfs__single-value__img" />{tag.name}</div>
  }));

  // const [selectedOption, setSelectedOption] = useState<SingleValue<OptionType>>(tagOptions.find(option => option.value === selectedTag) || null)
  const selectedOption = tagOptions.find(option => option.value === selectedTag) || null;


  function DropdownIndicator(props: DropdownIndicatorProps){
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDown className="tik4-tfs__i tik4-tfs__i--di" />
      </components.DropdownIndicator>
    );
  }

  function ValueContainer({children, ...props}: ValueContainerProps){
    return <components.ValueContainer {...props}>
      {children}
      {/* doing this to get the longest word so the select has the same with. (like standard browser select behaviour) */}
      <div className="tik4-tfs__shadow">
        {tagOptions.map((tag,i) => <div key={`tik4-tfs-shadow__v-${i}`} className="tik4-tfs__shadow__v">{tag.component}</div>)}
      </div>
      </components.ValueContainer>
  }

  // function SingleValue<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({children, ...props}: SingleValueProps<Option, IsMulti, Group>){
  //   return <components.SingleValue {...props}>
  //     {children}
  //     {/* doing this to get the longest word so the select has the same with. (like standard browser select behaviour) */}
  //     <div className="flex flex-col">
  //       {tagOptions.map((tag) => <div>{tag.label}</div>)}

  //     </div>
  //     </components.SingleValue>
  // }

  // function Input<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>({children, ...props}: InputProps<Option, IsMulti, Group>){
  //   return <components.Input {...props}>{children}</components.Input>
  // }
  function Placeholder({children, ...props}: PlaceholderProps){
    return <components.Placeholder {...props}><span className="tik4-tfs__placeholder__i">{children}</span></components.Placeholder>
  }

  return (
    <div className="tik4-tag-filter tik4-tag-filter--tfs">
      <div className="tik4-tag-filter__label"><div className="tik4-tag-filter__label__i">{t('liveblog.tags.filter.headline')}</div></div>
      <div className="tik4-tfs-c">
        <Select
          options={tagOptions}
          className="tik4-tfs"
          classNamePrefix="tik4-tfs"
          //TODO: fix ts error (only occurs with loadable)
          // @ts-ignore
          onChange={handleChange}
          components={{DropdownIndicator, ValueContainer, Placeholder}}
          // components={{ClearIndicator, DropdownIndicator, ValueContainer}}
          value={selectedOption}
          // isClearable
          // defaultMenuIsOpen={true}
          // menuIsOpen={true}
          placeholder={t('liveblog.tags.filter.search.placeholder')}
          loadingMessage={() => t('liveblog.tags.filter.search.loading')}
          noOptionsMessage={() => t('liveblog.tags.filter.search.no_options')}



        />
        {(selectedOption) &&
          <button className="tik4-tag-filter__r tik4-link" onClick={() => handleChange(null)} >
            <X className="tik4-icon tik4-tag-filter__r__i" /><span className="tik4-tag-filter__r__t">{t('liveblog.tags.filter.search.reset')}</span>
          </button>
        }
      </div>
    </div>
  );
}

function ListFilter({tags, onClick, selectedTag}: {tags: ITag[], onClick?: OnTagClickHandler, selectedTag?: string}) {
  const {t} = useTranslation("liveblog");

  return <div className="tik4-tag-filter tik4-tag-filter--lf">
    <div className="tik4-tag-filter__label"><div className="tik4-tag-filter__label__i">{t('liveblog.tags.filter.headline')}</div></div>
    { (selectedTag) &&
      <button className="tik4-tag-filter__r tik4-link" onClick={() => onClick && onClick(undefined)}>
        <X className="tik4-icon tik4-tag-filter__r__i" /><span className="tik4-tag-filter__r__t">{t('liveblog.tags.filter.search.reset')}</span>
      </button>
    }
    <ul className="tik4-tag-filter__list">
      {tags.map(tag => <li className="tik4-tag-filter__list__item" key={tag._id}>
        <Tag tag={tag} onClick={onClick} selected={tag._id === selectedTag}/>
      </li>)}
    </ul>
  </div>
}

export function LiveblogTagFilter({tags, onClick, selectedTag, eventTagNavigation}: LiveblogTagFilterProps) {
  if(eventTagNavigation === "filter-list" && tags.length <= FILTER_LIST_MAX_TAGS) {
    return <ListFilter tags={tags} onClick={onClick} selectedTag={selectedTag} />
  }
  if(eventTagNavigation === "filter-search" || (eventTagNavigation === "filter-list" && tags.length > FILTER_LIST_MAX_TAGS)) {
    return <SearchFilter tags={tags} onClick={onClick} selectedTag={selectedTag} />

  }
  return null;
}
