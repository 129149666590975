import React from "react";
import {useTranslation} from "react-i18next";
import {HtmlView} from "./HtmlView";
import {IEmojiContentBlock} from "@webng-types/write-model";

export function EmojiContentBlockView({block} : {block: IEmojiContentBlock}) {
  const {i18n} = useTranslation()

  return <div className={'tik4-em'}>
    <div className="tik4-em__em">{block.emoji}</div>
    <div className="tik4-em__txt">
      <HtmlView className={`tik4-rich-text tik4-rich-text--${i18n.language}`} html={block.title || ""} />
    </div>
  </div>
}
