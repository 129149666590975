import React from "react";
import {EmbedProviderProps} from "./EmbedProviders";

export function VideoWebEmbedView({oembed}: EmbedProviderProps) {
  return <div className={`tik4-we-video tik4-we-video--${oembed.provider_name?.toLowerCase()}`}>
    <div className="tik4-we-video__container" dangerouslySetInnerHTML={{__html: oembed.html || ""}} />
  </div>
}

export default VideoWebEmbedView
