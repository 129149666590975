import React, {Component, ErrorInfo, ReactNode} from 'react'



type DefaultErrorBoundaryState = {error: Error | null, info: ErrorInfo | null}

export class DefaultErrorBoundary extends Component<ErrorBoundaryProps, DefaultErrorBoundaryState> {
  public state: DefaultErrorBoundaryState = {
    error: null,
    info: null
  };

  // public static getDerivedStateFromError(error: Error): DefaultErrorBoundaryState {
  //   return { error };
  // }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    console.error(error)

    this.props.onError?.(error, info);
    this.setState({error, info});
  }

  handleClearError () {
    this.setState({ error: null, info: null })
  }

  public render() {
    const {error, info} = this.state;
    if (error !== null) {
      const {FallbackComponent} = this.props;
      if(FallbackComponent){
        return <FallbackComponent error={error} info={info!} clearError={this.handleClearError}/>;
      } else {
        return <React.Fragment />
      }
    }

    return this.props.children;
  }
}

export interface FallbackComponentProps {
  error: Error
  info: React.ErrorInfo
  clearError: () => void
}

interface ErrorBoundaryProps {
  children?: ReactNode
  FallbackComponent?: React.ComponentType<FallbackComponentProps>
  onError?: (error: Error, info: React.ErrorInfo) => void
}

export type ErrorBoundaryComp = React.ComponentClass<ErrorBoundaryProps>

export type DebugEventMetadata = {[p: string]: any}

interface CommonComponents {
  ErrorBoundary: ErrorBoundaryComp
  notifyError: (e: Error|string, customAttributes?: Record<string, string>) => void
  debugEvent: (title: string, metadata: DebugEventMetadata) => void
  fetchFn: typeof fetch
}

export let ErrorBoundary: ErrorBoundaryComp = DefaultErrorBoundary;
export let notifyError = (e: Error|string, customAttributes?: Record<string, string>) => { console.log(e) }
export let debugEvent = (title: string, metadata: DebugEventMetadata) => console.trace(title, metadata)
export let fetchFn: typeof fetch = typeof window !== 'undefined' ? window.fetch : (input, init) => { throw new Error("global fetchFn undefined") }

export function setupCommonComponents(components: Partial<CommonComponents>) {
  if (components.ErrorBoundary) ErrorBoundary = components.ErrorBoundary;
  if (components.notifyError) notifyError = components.notifyError;
  if (components.debugEvent) debugEvent = components.debugEvent;
  if (components.fetchFn) fetchFn = components.fetchFn;
}
