import React, {ReactNode} from "react";

interface LiveblogGoalListProps {
  children: ReactNode
}

export function LiveblogGameSummary({children}: LiveblogGoalListProps) {
  return <React.Fragment>
    <div className="tik4-gs">
      {/* <div className="tik4-gs__label">{t('liveblog.goal_list.headline')}</div> */}
      <div className="tik4-gsg">
        {children}
      </div>
    </div>
  </React.Fragment>
}
