import {useCallback, useEffect, useRef, useState} from 'react';
import useResizeObserver from "use-resize-observer";


export default function useBreakpoints(breakpoints: [string, number][]){
  const ref = useRef<HTMLDivElement|null>(null);
  const [breakpoint, setBreakpoint] = useState<string|undefined>(undefined);
  const [resizeObserverAvailable, setResizeObserverAvailable] = useState(false);

  useEffect(() => {
    if(window && window.ResizeObserver){
      setResizeObserverAvailable(true);
    }
  }, [setResizeObserverAvailable]);

  const  updateBreakpoint = useCallback((width: number) => {
    for (let i = 0; i < breakpoints.length; i++) {
      if (width <= breakpoints[i][1]) {
        setBreakpoint(breakpoints[i][0]);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setBreakpoint]);

  useResizeObserver<HTMLElement>({
    onResize: useCallback(
      ({ width }: {width: number | undefined, height: number | undefined}) => {
        if(width) {
          updateBreakpoint(width);
        }
      },
      [updateBreakpoint]
    ),
    ref: resizeObserverAvailable ? ref : null
  });

  const setRef = useCallback((elem: HTMLDivElement|null) => {
    ref.current = elem;
    if(!resizeObserverAvailable && elem){
      updateBreakpoint(elem.offsetWidth);
    }
  }, [updateBreakpoint, resizeObserverAvailable]);

  return { ref: setRef, breakpoint }
}
