import { LiveblogEvent } from "@webng/liveblog";
import * as api from "./api";

export const tickarooInitEvent = "tickarooInit";
export const tickarooLiveblogInitEvent = "tickarooLiveblogInit";
export const tickarooLiveblogTrackingEvent = "tickarooLiveblogTracking";
export const tickarooLiveblogCustomConsentEvent =
  "tickarooLiveblogCustomConsent";
export const tickarooLiveblogCustomConsentSuccessEvent =
  "tickarooLiveblogCustomConsentSuccess";

export type InitEventDetail = typeof api;
export type LiveblogInitEventDetail = { liveblogId: string };
export type LiveblogTrackingEventDetail = LiveblogEvent;
export type LiveblogCustomerContestEventDetail = LiveblogEvent & {
  provider: string;
  consent: boolean;
};

export function fireInitEvent() {
  const initEvent = new CustomEvent<InitEventDetail>(tickarooInitEvent, {
    detail: api,
    bubbles: true,
    cancelable: false,
  });

  window.dispatchEvent(initEvent);
}

export function fireTickarooLiveblogInitEvent(
  el: Element,
  detail: LiveblogInitEventDetail
) {
  const event = new CustomEvent<LiveblogInitEventDetail>(
    tickarooLiveblogInitEvent,
    {
      detail: detail,
      bubbles: true,
      cancelable: false,
    }
  );

  el.dispatchEvent(event);
}

export function fireTickarooLiveblogTracking(
  el: Element,
  detail: LiveblogTrackingEventDetail
) {
  const event = new CustomEvent<LiveblogTrackingEventDetail>(
    tickarooLiveblogTrackingEvent,
    {
      detail: detail,
      bubbles: true,
      cancelable: false,
    }
  );

  el.dispatchEvent(event);
}

export function firetickarooLiveblogCustomConsent(
  el: Element,
  detail: LiveblogCustomerContestEventDetail
) {
  const event = new CustomEvent<LiveblogCustomerContestEventDetail>(
    tickarooLiveblogCustomConsentEvent,
    {
      detail: detail,
      bubbles: true,
      cancelable: false,
    }
  );

  el.dispatchEvent(event);
}
