import {parseDataTagProps, parseDefaultProps, parsePartialQueryProps} from "../utils/propsParser";
import {parseBoolean, PropsParser} from "@webng/validations";


export interface GlobalSettingsQuery {
  useHydrate: boolean
  localDev: boolean
}

export interface GlobalSettings extends GlobalSettingsQuery{
  baseUrl: string
  scriptBaseUrl: string
  analyticsBaseUrl: string
}

export const globalQuerySettingsParser: PropsParser<GlobalSettingsQuery> = {
  useHydrate: i => parseBoolean("useHydrate", i, true),
  localDev: i => parseBoolean("localDev", i, false)
}

export const globalSettingsParser: PropsParser<GlobalSettings> = {
  baseUrl: i => i || "https://cdn.tickaroo.com",
  analyticsBaseUrl: i => i,
  scriptBaseUrl: i => i || "/webng/embedjs",
  ...globalQuerySettingsParser
}


export function findClientScriptTag() {
  const scriptTags = document.getElementsByTagName("script");
  for(let i = 0; i < scriptTags.length; ++i) {
    const scriptTag = scriptTags[i];
    if(scriptTag.src) {
      const match = scriptTag.src.match(/\/tik4\.js/);
      if(match) {
        return scriptTag;
      }
    }
  }
  return null;
}

function parseGlobalScriptSettings() {
  const scriptTag = findClientScriptTag();
  if(scriptTag) {
    const parsedSettings = parseDataTagProps(globalSettingsParser, scriptTag)
    parsedSettings.scriptBaseUrl = scriptTag.src.replace(/\/tik4\.js.*/, '');
    parsedSettings.analyticsBaseUrl = parsedSettings.analyticsBaseUrl || parsedSettings.baseUrl
    return parsedSettings
  } else {
    return parseDefaultProps(globalSettingsParser);
  }
}

function parseGlobalQuerySettings() {
  return parsePartialQueryProps(globalQuerySettingsParser)
}

function parseSiteHacks(): Partial<GlobalSettings> {
  if(window.location.hostname.indexOf("faz.net") !== -1) {
    return {
      useHydrate: false
    }
  } else {
    return {}
  }
}

function parseGlobalSettings() {
  return Object.assign({},
    parseGlobalScriptSettings(),
    parseSiteHacks(),
    parseGlobalQuerySettings())
}

const globalSettings: GlobalSettings = parseGlobalSettings();

if(!globalSettings.baseUrl.match(/^https?.:\/\/*/)) throw new Error("ValidationError GlobalSettings.baseUrl is not a URL")

export default globalSettings


