import React, {useCallback, useMemo, useState} from "react";
import { EmbedProviderProps } from "./EmbedProviders";
import { ensureScriptTag } from "./tagManager";
import {generateRandomId} from "@webng/uniqueid-generator";

interface AgnoplayConfiguration {
  videoId: string;
  brand: string;
  autoplay?: boolean;
  element: HTMLDivElement;
}

let initPlayerConfigurations: AgnoplayConfiguration[] = [];

function initAGNOPlayer() {
  initPlayerConfigurations.forEach((configuration) => {
    (window as any).AGNO.insertPlayer(
      {
        brand: configuration.brand,
        videoId: configuration.videoId,
        url: window.location.href,
      },
      configuration.element
    );
  });
  initPlayerConfigurations = [];
}

function parseOembedHtml(html: string): { videoId: string; brand: string; autoplay: boolean } | null {
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const div = doc.querySelector("div");

    if (div && div.dataset) {
      const videoId = div.dataset.videoId || "";
      const brand = div.dataset.brand || "";
      const autoplay = div.dataset.autoplay === "true";

      return { videoId, brand, autoplay };
    }
  } catch (error) {
    console.warn("Error parsing oembed HTML", error);
  }
  return null;
}

export function Agnoplay({ oembed }: EmbedProviderProps) {
  const [id, ] = useState(generateRandomId("agno"));

  const oembedConfig = useMemo(() => {
    return parseOembedHtml(oembed.html || "")
  }, [oembed.html]);

  const ref = useCallback((element: HTMLDivElement) => {
    if (!element || !oembedConfig) return;

    let configuration: AgnoplayConfiguration = {...oembedConfig, element};
    if (typeof (window as any).AGNO !== 'undefined' && typeof (window as any).AGNO.insertPlayer !== 'undefined') {
      (window as any).AGNO.insertPlayer({...configuration, url: window.location.href}, element);
    } else {
      initPlayerConfigurations.push(configuration);
      ensureScriptTag({
        src: "https://player.agnoplay.com/static/agnoplay/js/agnoplay.js",
        async: true,
        crossOrigin: "anonymous",
        onload: () => {
          initAGNOPlayer();
        },
      });
    }
  }, [oembedConfig]);

  return <div id={`agnoplay-${oembedConfig?.videoId}-${id}`} ref={ref} />;
}
