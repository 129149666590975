import React, {useCallback} from "react";
import {EmbedProviderProps} from "./EmbedProviders";
import {ensureScriptTag} from "./tagManager";

export function Flourish({oembed}: EmbedProviderProps) {

  const ref = useCallback((element: HTMLDivElement|null) => {
     if(element) {
      if (typeof (window as any).Flourish !== 'undefined' && typeof (window as any).Flourish.loadEmbed !== 'undefined') {
        (window as any).Flourish.loadEmbed(element.firstChild);
      } else {
        if((window as any).Flourish === undefined) {
          (window as any).Flourish = {};
        }
        ensureScriptTag({
          src: 'https://public.flourish.studio/resources/embed.js',
          async: true,
          defer: true,
          crossOrigin: "anonymous",
          referrerPolicy: "no-referrer"
        });
      }
    }
  }, [])

  return <div className="tik4-we-flourish" ref={ref} dangerouslySetInnerHTML={{__html: oembed.html || ""}} />
}

export default Flourish
