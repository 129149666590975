import React, {ReactNode} from "react";
import {IGame} from "@webng-types/write-model";
import {useTranslation} from "react-i18next";

interface LiveblogHighlightSummaryProps {
  game: IGame
  children: ReactNode
}

export function LiveblogHighlightSummary({game, children}: LiveblogHighlightSummaryProps) {
  const {t} = useTranslation("liveblog")
  return <React.Fragment>
    {game.summary?.highlights && game.summary.highlights.refs && game.summary.highlights.refs.length > 0 &&
    <div className="tik4-hs">
      <div className="tik4-hs__label"><span className="tik4-hs__label__t">{t('liveblog.highlight_summary.headline')}</span></div>
      <div className="tik4-hs__list" role="list">
        {children}
      </div>
    </div>
    }
  </React.Fragment>
}
