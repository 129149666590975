import React from "react"
import {ApiImage} from "./ApiImage";
import {exactSizeImageUrl} from "./converter/imageUrl";


export function TagImage({image, className}: {image: string|undefined, className?:string}) {
  let tagImage = undefined;
  let tagImage2x = undefined;
  if (image) {
    tagImage = exactSizeImageUrl(image, {width: 16, height: 16});
    tagImage2x = exactSizeImageUrl(image, {width: 32, height: 32});
  } else {
    return null
  }

  if (tagImage && tagImage2x)  {
    return <ApiImage srcSet={`${tagImage.url} ${tagImage.width}w, ${tagImage2x.url} ${tagImage2x.width}w`}
      className={className || ""}
      height={tagImage.height}
      width={tagImage.width}
      alt=""
      src={tagImage.url}
    />
  } else {
    return null
  }
}
