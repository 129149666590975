import {IScoreboardScore} from "@webng-types/write-model/index";

function parseScore(score?: string){
  if(score && !Number.isNaN(score)){
    return Number.parseInt(score, 10)
  } else {
    return 0;
  }
}

export default function calculateSetScore(scores: IScoreboardScore[]) {
  if(!scores || scores.length === 0){
    return ["", ""];
  }
  // if(scores.length == 1){
  //   return [scores[0].home_score, scores[0].away_score];
  // }
  const results = scores.reduce((res, score) => {
    const homeScore = parseScore(score.home_score);
    const awayScore = parseScore(score.away_score);
    if (homeScore > awayScore) {
      return [res[0] + 1, res[1]];
    } else if (awayScore > homeScore) {
      return [res[0], res[1] + 1];
    }
    return res;
  }, [0, 0]);
  return [results[0].toString(), results[1].toString()];
}
