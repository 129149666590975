import globalSettings from "../tik4/globalSettings";
import {sendEvents} from "./sendEvents";
import errorStackParser from 'error-stack-parser'
import {DebugEventMetadata} from "@webng/react-app-common";

const hasNecessaryFields = (error:any) =>
  (typeof error.name === 'string' || typeof error.errorClass === 'string') &&
  (typeof error.message === 'string' || typeof error.errorMessage === 'string')

function isError(value: object): value is Error {
  switch (Object.prototype.toString.call(value)) {
    case '[object Error]': return true;
    case '[object Exception]': return true;
    case '[object DOMException]': return true;
    default: return value instanceof Error;
  }
}

function normalizeError(maybeError: any) {
  switch (typeof maybeError) {
    case 'object':
      if (maybeError !== null && isError(maybeError)) {
        return maybeError
      } else if (maybeError !== null && hasNecessaryFields(maybeError)) {
        const error = new Error(maybeError.message || maybeError.errorMessage)
        error.name = maybeError.name || maybeError.errorClass
        return error
      } else {
        return new Error(String(maybeError))
      }
    default:
      return new Error(String(maybeError))
  }
}

interface DebugEvent {
  ts: number
  title: string
  meta?: DebugEventMetadata
}

interface ErrorData {
  metaData?: Record<string, string>
  debugEvents?: DebugEvent[]
}

export function sendError(error: any, data: ErrorData = {} ) {
  const url = globalSettings.analyticsBaseUrl + "/api/collect/v7/error-liveblog.json";

  const normalizedError = normalizeError(error);

  let stack: any[] = []
  try {
    stack = errorStackParser.parse(normalizedError).map(f => ({
      file: f.fileName || "<unknown>",
      lineNumber: f.lineNumber || 0,
      columnNumber: f.columnNumber,
      method: f.functionName || "<unknown>",
    }))
  } catch(e) {
    // ignore stack parsing error, send empty stack
  }

  const event:any = {
    uaLocation: window.location.href.substring(0, 2048 ),
    errorClass: normalizedError.name,
    message: normalizedError.message,
    stacktrace: stack,
  }

  if(data.metaData) {
    event.metaData = data.metaData
  }

  if(data.debugEvents) {
    event.debugEvents = data.debugEvents
  }

  sendEvents(url, event)
}
