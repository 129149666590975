import {IEvent} from "@webng-types/write-model";
import {legacyEventInfoToContentsConverter} from "./legacyEventInfoToContentsConverter";

export function maybeLegacyEventInfoToContentsConverter(event: IEvent) {
  if(!event.contents || event.contents.length === 0) {
    return legacyEventInfoToContentsConverter(event)
  } else {
    return event.contents
  }
}
