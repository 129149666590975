import { parseJSON, PropsParser, requireString, parseBoolean } from "@webng/validations";
import {
  LiveblogCoreWidgetInitialData,
  LiveblogThemeConfiguration,
  liveblogThemeConfigurationPropsParser
} from "@webng-types/embedjs";
import {AdManager} from "../../ads/AdManager";


export interface LiveblogCoreWidgetTagProps extends LiveblogThemeConfiguration {
  liveblogId: string
  clientId: string
  initialData: LiveblogCoreWidgetInitialData
  css: string
  customerConsent: boolean
  disableTracking: boolean
}

export interface LiveblogCoreWidgetQueryProps {
  eventId: string
}

export interface LiveblogCoreWidgetProps extends LiveblogCoreWidgetTagProps, LiveblogCoreWidgetQueryProps {
}
//
// export const defaultLiveblogWidgetTagProps: LiveblogCoreWidgetTagProps = {
//
//   css: ""
// }

export const liveblogCoreWidgetTagPropsParser: PropsParser<LiveblogCoreWidgetTagProps> = {
  ...liveblogThemeConfigurationPropsParser,
  clientId: i => requireString("clientId", i),
  liveblogId: i => requireString("liveblogId", i),
  initialData: i => parseJSON("initialData", i, {}),
  css: i => i || "",
  customerConsent: i => parseBoolean('customerConsent', i, false),
  disableTracking: i => parseBoolean('disableTracking', i, false)
}

export const LiveblogCoreWidgetQueryPropsParser: PropsParser<LiveblogCoreWidgetQueryProps> = {
  eventId: i => i || ""
}

export interface NavigateToEventIdOptions {
  topOffset?: number
}

export interface LiveblogCoreApi {
  loadMoreTop: () => PromiseLike<void>
  loadMoreBottom: () => PromiseLike<void>
  navigateToEventId: (eventId: string, options?: NavigateToEventIdOptions) => PromiseLike<boolean>
  hasEventIdLoaded: (eventId: string) => boolean
  setConsent: (provider: string, consent: boolean) => void
  setAdManager: (newAdManager: AdManager) => void
}
