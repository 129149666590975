import {useTranslation} from "react-i18next";
import {useMemo} from "react";
import React from "react";

export function expandLocale(locale: string) {
  if (locale === "en") {
    return "en-US";
  } else if (locale === "de") {
    return "de-DE";
  } else {
    return locale
  }
}

export function createDateTimeFormatter(locale: string, options: Intl.DateTimeFormatOptions): Intl.DateTimeFormat {
  return new Intl.DateTimeFormat(expandLocale(locale), options);
}

interface FormattedDateTimeProps {
  date: Date
  format: Intl.DateTimeFormatOptions
}

export function FormattedDateTime({date, format}: FormattedDateTimeProps) {
  const {i18n} = useTranslation();

  const formatter = useMemo(() => {
    return createDateTimeFormatter(i18n.language, format)
  }, [i18n.language, format])

  return <React.Fragment>{formatter.format(date)}</React.Fragment>
}
