import {useTranslation} from "react-i18next";
import {Star} from "react-feather";
import {Pin} from "@webng/icons-common";
import React from "react";
import { EventSportIcon } from "./event/EventSportIcon";
import {ISportEventContentBlock} from "@webng-types/write-model/index";

interface NiceDateTimeHighlightsProps {
    isStickyEvent: boolean
    isHighlightEvent: boolean
    sportEvent?: ISportEventContentBlock
}

export function NiceDateTimeHighlights({isStickyEvent, isHighlightEvent, sportEvent}: NiceDateTimeHighlightsProps) {
    const {t} = useTranslation("liveblog")

    if (isStickyEvent || isHighlightEvent || sportEvent) {
        return <span className="tik4-meta__ts__icons">
          { isHighlightEvent &&
            <Star className="tik4-icon tik4-meta__ts__icon tik4-meta__ts__icon--highlight"
                aria-label={t('liveblog.event.highlight.label')}/>
          }
          { isStickyEvent &&
            <Pin className="tik4-icon tik4-meta__ts__icon tik4-meta__ts__icon--pin"
                 aria-label={t('liveblog.event.sticky.label')}/>
          }
          { sportEvent && <EventSportIcon className="tik4-icon tik4-icon--sport tik4-meta__ts__icon tik4-meta__ts__icon--sport" block={sportEvent} />}
        </span>
    } else {
        return <></>
    }
}
