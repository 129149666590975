const MAX_RANDOM = 0x19a0ff; // .to_s(36) = "zzzz"

const secureRnd = function() {
  const cryptoObj = window.crypto || (window as any).msCrypto;
  if(cryptoObj && cryptoObj.getRandomValues) {
    const array = new Uint32Array(1);
    cryptoObj.getRandomValues(array);
    return array[0] % MAX_RANDOM;
  } else {
    return Math.floor(Math.random() * MAX_RANDOM);
  }
};

const pad = '0000';
const zeroPad = function(str: string) {
  return pad.substring(0, pad.length - str.length) + str;
};

const generateUniqueId = function() {
  const ts = new Date().getTime();
  return zeroPad(secureRnd().toString(36)) + zeroPad(secureRnd().toString(36)) + ts.toString(36);
};

export default generateUniqueId;
