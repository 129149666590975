
export class ValidationError extends Error {
  readonly _type = 'ValidationError'
}

export function isValidationError(v: ValidationError|any): v is ValidationError {
  return (v as ValidationError)._type === 'ValidationError';
}




export function normalizeMultiple(s: any): string|undefined {
  if (s) {
    if(typeof s === 'string') {
      return s;
    } else if(Array.isArray(s)) {
      return s[s.length - 1];
    } else {
      throw new ValidationError(`Unexpected Parameter Format: ${s} (${typeof s})`);
    }
  } else {
    return undefined
  }
}

export function requireString(name: string, s: string | string[] | null | undefined): string {
  const n = normalizeMultiple(s);
  if (n) {
    return n;
  } else {
    throw new ValidationError("Missing Parameter " + name);
  }
}

export function requireStringOrDefault(s: string | string[] | null | undefined, d: string): string {
  const n = normalizeMultiple(s);
  if (n) {
    return n;
  } else {
    return d
  }
}


export function parseStringFormat(name: string, s: string | string[] | null | undefined, format: RegExp, defaultValue: string): string {
  const n = normalizeMultiple(s);
  if (n) {
    if(n.match(format)) {
      return n;
    } else {
      throw new ValidationError("Parameter not matching format " + name + " " + n);
    }
  } else {
    return defaultValue
  }
}

export function parseStringEnum<T extends string>(name: string, s: string | string[] | null | undefined, possibleValues: ReadonlyArray<T>, defaultValue: T): T {
  const n = normalizeMultiple(s);
  if(n) {
    if(possibleValues.includes(n as T)) {
      return n as T;
    } else {
      throw new ValidationError("Parameter " + name + " not in " + possibleValues);
    }
  } else {
    return defaultValue
  }
}

export function requireStringEnum<T extends string>(name: string, s: string | string[] | null | undefined, possibleValues: ReadonlyArray<T>): T {
  const n = normalizeMultiple(s);
  if(n) {
    if(possibleValues.includes(n as T)) {
      return n as T;
    } else {
      throw new ValidationError("Parameter " + name + " not in " + possibleValues);
    }
  } else {
    throw new ValidationError("Missing Parameter " + name);
  }
}

export function parseJSON<T>(name: string, s: string | string[] | null | undefined, defaultValue: T) {
  if(s) {
    if(typeof s === "object"){
      return s;
    }
    const n = normalizeMultiple(s)!;
    try {
      return JSON.parse(n);
    } catch(e) {
      if(e instanceof Error) {
        throw new ValidationError("Parameter " + name + " invalid json " + e.message)
      } else {
        throw new ValidationError("Parameter " + name + " invalid json " + e)
      }
    }
  } else {
    return defaultValue;
  }
}

export function parseUrl(name: string, url: string | undefined, required: boolean | undefined): string | undefined {
  if (url) {
    try {
      new URL(url);
      return url;
    } catch (e){
      throw new ValidationError(`Invalid url ${url} (${name})`);
    }
  } else {
    if(required){
      throw new ValidationError("Missing Parameter " + name);
    }
    return undefined;
  }
}

export function parseLocale(s: string | null | undefined, supportedLocales: readonly string[]){
  if (s) {
    const sLower = s.toLowerCase()
    if (supportedLocales.includes(sLower)) {
      return sLower;
    } else {
      throw new ValidationError(`Invalid locale. Supported ${supportedLocales.join(", ")}`);
    }
  }
  return undefined;
}

export function parseLocaleWithFallback(s: string | null | undefined, supportedLocales: readonly string[]){
  var locale = parseLocale(s, supportedLocales)
  if(locale !== undefined) {
    return locale;
  } else {
    return 'en';
  }
}

export function parseBoolean(name: string, s: string | string[] | boolean | null | undefined, def: boolean): boolean {
  if (s === undefined || s === null) {
    return def;
  } else if(typeof s === 'boolean') {
    return s
  } else if(Array.isArray(s)) {
    return parseBoolean(name, s[s.length - 1], def);
  } else if (s === 'on' || s === 'true') {
    return true;
  } else if (s === 'off' || s === 'false') {
    return false;
  } else {
    throw new ValidationError("Invalid " + name);
  }
}

export function requireBoolean(name: string, value: boolean | null | undefined): boolean {
  if (typeof value === "boolean") {
    return value;
  } else {
    throw new ValidationError(name + " must be a boolean");
  }
}

export function parseThreewayBoolean(name: string, s: string | string[] | null | undefined, def: boolean | undefined = undefined): boolean | undefined {
  const n = normalizeMultiple(s);
  if (n === undefined || n === null || n === 'auto') {
    return def;
  } else {
    return parseBoolean(name, n, true);
  }
}

export function parseNumber(input: number | string | string[] | null | undefined, min: number, max: number, def: number, zeroIsMax: boolean = false): number {
  let output = def;
  if(typeof input === "number"){
    output = input;
  } else {
    const n = normalizeMultiple(input);
    if(n){
      const parsed = Number.parseInt(n, 10);
      if (!Number.isNaN(parsed)) {
        output = parsed;
      } else {
        throw new ValidationError("Invalid number " + n);
      }
    }
  }
  if (zeroIsMax && output === 0) return max;
  if (output < min) return min;
  if (output > max) return max;
  return output;
}

export function requireNumber(name: string, inputNumber: number | null | undefined, min: number, max: number): number {
  if (inputNumber) {
    if (!Number.isNaN(inputNumber)) {
      if (inputNumber < min){
        throw new ValidationError(name + " must be greater than " + min);
      }
      if (inputNumber > max){
        throw new ValidationError(name + " must be less than " + max);
      }
      return inputNumber;
    } else {
      throw new ValidationError(name + " is invalid");
    }
  } else {
    throw new ValidationError(name + " missing");
  }
}

export function parseArray(s: string | null | undefined): string[] {
  if (s) {
    return s.split(",");
  } else {
    return [];
  }
}

export type PropsParser<T> = {
  [Property in keyof T]: ((i: any) => T[Property])
}

export type Retriever<T> = (k: keyof T) => string | null


// export function validateObject<T>(parser: PropsParser<T>, input: any){
//   return validateProps(parser, (key) => input[key]);
// }
//
// export function validateProps<T>(parser: PropsParser<T>, retriever: Retriever<T>) {
//   const keys = Object.keys(parser) as (keyof T)[]
//
//   const errors: ValidationErrorItem[] = [];
//   keys.forEach(k => {
//     try {
//       parser[k](retriever(k));
//     } catch (e) {
//       if (isValidationError(e)) {
//         errors.push({path: [k as any], message: e.message})
//       } else {
//         throw e;
//       }
//     }
//   })
//
//   return errors;
// }

export function parseObject<T>(parser: PropsParser<T>, input: any){
  return parseProps(parser, (key) => input[key]);
}

export function parsePartialObject<T>(parser: PropsParser<T>, input: any){
  return parsePartialProps(parser, (key) => input[key]);
}

export function parseProps<T>(parser: PropsParser<T>, retriever: Retriever<T>) {
  const keys = Object.keys(parser) as (keyof T)[]

  const ret: Partial<T> = {}
  keys.forEach(k => ret[k] = parser[k](retriever(k)))

  return ret as T
}

export function parsePartialProps<T>(parser: PropsParser<T>, retriever: Retriever<T>): Partial<T> {
  const keys = Object.keys(parser) as (keyof T)[]
  const ret: Partial<T> = {}
  keys.forEach(k => {
    const v = retriever(k);
    if(v != null) {
      ret[k] = parser[k](v)
    }
  })

  return ret
}
