import React, {HTMLAttributes} from "react";

function createMarkup(html: string) {
  return {__html: html};
}

interface HtmlViewProps extends HTMLAttributes<HTMLDivElement> {
  html: string
}

export function HtmlView({html, ...attributes} : HtmlViewProps) {
  return <div dangerouslySetInnerHTML={ createMarkup(html) }  {...attributes} />
}

export function SpanView({html, ...attributes} : HtmlViewProps) {
  return <span dangerouslySetInnerHTML={ createMarkup(html) }  {...attributes} />
}
