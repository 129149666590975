import * as React from "react";
import type { SVGProps } from "react";
const RedCard = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(6 3.5)">
      <rect
        width={13.75}
        height={18.75}
        x={-0.88}
        y={-0.88}
        fill="#FF1212"
        stroke="#FFF"
        strokeWidth={1.75}
        rx={1.2}
      />
      <rect width={13} height={18} x={-0.5} y={-0.5} stroke="#000" rx={1.2} />
    </g>
  </svg>
);
export default RedCard;
