import React from "react";
import {IGame, ISportEventContentBlock} from "@webng-types/write-model";
import {useSportEventSpec} from "../utils/useSportEventSpec";
import {SportEventContentView} from "./SportEventContentView";

export function SportEventContentBlockViewContainer({children} : {children:React.ReactNode}) {
  return <div className="tik4-se">
    <div className="tik4-se__w">
      {children}
    </div>
  </div>
}

export function SportEventContentBlockView({block, game}: {block: ISportEventContentBlock, game: IGame}) {
  const {sportstypeEventSpec} = useSportEventSpec(block.event_type)

  return <SportEventContentBlockViewContainer>

    <div
      className={`tik4-se__m ${block.time_of_play ? 'tik4-se__m--w-top' : ''} ${block.phase ? 'tik4-se__m--w-pt' : ''}`}>
      {sportstypeEventSpec &&
        <div className="tik4-se__m__i">
          <div className="tik4-se__m__i__c">
            <sportstypeEventSpec.svg className="tik4-se__m__i__svg"/>
          </div>
        </div>
      }

      {block.phase &&
        <div className="tik4-se__m__pt">{block.phase.name}</div>
      }

      <div className="tik4-se__m__top-i">
        {block.time_of_play &&
          <div className="tik4-se__m__top-i__top">{block.time_of_play}</div>
        }
        {sportstypeEventSpec &&
          <div className="tik4-se__m__top-i__i">
            <sportstypeEventSpec.svg className="tik4-se__m__top-i__i__svg"/>
          </div>
        }
      </div>
    </div>

    <SportEventContentView block={block} game={game} />


  </SportEventContentBlockViewContainer>

}
