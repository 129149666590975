import {IEvent} from "@webng-types/write-model";
import {AbstractAdManager} from "./AbstractAdManager";

type ExternalAdCallback = (event: IEvent) => any

export class CallbackAdManager extends AbstractAdManager {

  private readonly _callback: ExternalAdCallback

  constructor(callback: ExternalAdCallback) {
    super()
    this._callback = callback
  }

  update(rows: IEvent[]) {
    for(let i = 0; i < rows.length; ++i) {
      try {
        const result = this._callback(rows[i])
        if (result === undefined || typeof result === 'string') {
          this.notify(rows[i], result)
        } else {
          console.warn("CallbackAdManager: Callback must return a string or undefined")
        }
      } catch (e) {
        console.error(e)
      }
    }
  }
}


