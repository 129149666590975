import {LoadErrorView} from "../LoadErrorView";
import {LiveblogCoreWidget} from "../liveblog-core/LiveblogCoreWidget";
import {EnvironmentProvider, ErrorBoundary} from "@webng/react-app-common";
import globalSettings from "../globalSettings";
import {LiveblogWidgetProps} from "./LiveblogWidgetProps";
import React, {ForwardedRef, forwardRef, useMemo} from "react";
import {useLiveblogWidgetData} from "./useLiveblogWidgetData";
import {parseObject, parsePartialObject} from "@webng/validations";
import {
  LiveblogThemeConfiguration,
  liveblogThemeConfigurationDefaultValues,
  liveblogThemeConfigurationPropsParser
} from "@webng-types/embedjs";
import {LiveblogCoreApi} from "../liveblog-core/LiveblogCoreWidgetProps";

interface LiveblogControllerProps extends LiveblogWidgetProps {
  overrideConfiguration: Partial<LiveblogThemeConfiguration>
}

function LiveblogControllerImp({ clientId, themeId, liveblogId, eventId, initialData, customerConsent, overrideConfiguration, disableTracking }: LiveblogControllerProps, ref: ForwardedRef<LiveblogCoreApi>) {
  const { error, configuration, css, ...coreInitialData } = useLiveblogWidgetData({
    clientId,
    themeId,
    liveblogId,
    limit: overrideConfiguration.limit,
    sort: overrideConfiguration.sort,
    initialData
  })

  const finalConfiguration = useMemo(() => {
    if (configuration) {
      const parsedConfiguration = parseObject(liveblogThemeConfigurationPropsParser, configuration)
      const parsedOverrideConfiguration = parsePartialObject(liveblogThemeConfigurationPropsParser, overrideConfiguration)
      return Object.assign({}, liveblogThemeConfigurationDefaultValues, parsedConfiguration, parsedOverrideConfiguration)
    } else {
      return undefined
    }
  }, [configuration, overrideConfiguration])

  if (error !== undefined) {
    return <LoadErrorView status={error} />
  } else if (finalConfiguration) {
    return <LiveblogCoreWidget ref={ref}
      clientId={clientId}
      initialData={coreInitialData || {}}
      css={css || ""}
      eventId={eventId}
      liveblogId={liveblogId}
      customerConsent={customerConsent}
      disableTracking={disableTracking}
      {...finalConfiguration} />
  } else {
    return <React.Fragment />
  }
}


const LiveblogController = forwardRef(LiveblogControllerImp)

// this has to be a class component so ReactDOM.render returns a reference to it
// class LiveblogWidget extends React.Component<LiveblogWidgetProps, any> implements LiveblogCoreApi {
//   private controllerRef: RefObject<LiveblogCoreApi>
//
//   constructor(props: Readonly<LiveblogWidgetProps> | LiveblogWidgetProps) {
//     super(props);
//     this.controllerRef = React.createRef<LiveblogCoreApi>()
//   }
//
//   setConsent(provider: string, consent: boolean): void {
//     if (this.controllerRef.current) {
//       return this.controllerRef.current.setConsent(provider, consent);
//     } else {
//       return notInitialized()
//     }
//   }
//
//   hasEventIdLoaded(eventId: string): boolean {
//     if (this.controllerRef.current) {
//       return this.controllerRef.current.hasEventIdLoaded(eventId);
//     } else {
//       return notInitialized()
//     }
//   }
//
//   loadMoreBottom(): PromiseLike<void> {
//     if (this.controllerRef.current) {
//       return this.controllerRef.current.loadMoreBottom();
//     } else {
//       return notInitialized();
//     }
//   }
//
//   loadMoreTop(): PromiseLike<void> {
//     if (this.controllerRef.current) {
//       return this.controllerRef.current.loadMoreTop();
//     } else {
//       return notInitialized();
//     }
//   }
//
//   navigateToEventId(eventId: string): PromiseLike<boolean> {
//     if (this.controllerRef.current) {
//       return this.controllerRef.current.navigateToEventId(eventId);
//     } else {
//       return notInitialized();
//     }
//   }
//
//   setAdManager(newAdManager: AdManager): void {
//     if (this.controllerRef.current) {
//       return this.controllerRef.current.setAdManager(newAdManager);
//     } else {
//       return notInitialized();
//     }
//   }
//
//   render() {
//     const { clientId, ...props } = this.props
//     return <React.StrictMode>
//       <ErrorBoundary>
//         <EnvironmentProvider apiHost={globalSettings.baseUrl} clientId={clientId}>
//           <LiveblogController ref={this.controllerRef} clientId={clientId} {...props} />
//         </EnvironmentProvider>
//       </ErrorBoundary>
//     </React.StrictMode>
//   }
// }
function LiveblogWidgetImp({clientId, ...props}: LiveblogWidgetProps, ref: ForwardedRef<LiveblogCoreApi>) {
  return <React.StrictMode>
    <ErrorBoundary>
          <EnvironmentProvider apiHost={globalSettings.baseUrl} clientId={clientId}>
        <LiveblogController ref={ref} clientId={clientId} {...props} />
      </EnvironmentProvider>
    </ErrorBoundary>
  </React.StrictMode>
}


export const LiveblogWidget = forwardRef(LiveblogWidgetImp)

