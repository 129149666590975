import React, {
  createContext,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import {useLiveblogWrapperLayout} from "./LiveblogWrapper";

function tik4OuterHeight(el: HTMLElement) {
  let height = el.offsetHeight || 0;

  const style = getComputedStyle(el);

  height += (parseInt(style.marginTop) || 0) + (parseInt(style.marginBottom) || 0);
  return height;
}

function tik4GetEventItemHeight(item: HTMLDivElement) {
  let height = 0;
  const childs = item.children;
  for (let i = 0; i < childs.length; i++) {
    const element = childs[i];
    // height += element.getBoundingClientRect().height;
    height += tik4OuterHeight(element as HTMLElement);
  }
  const style = getComputedStyle(item);

  height += (parseInt(style.paddingTop) || 0) + (parseInt(style.paddingBottom) || 0);

  return height;
}

const EmptyObject = {}

function tik4ResizeMasonryItem(grid: HTMLDivElement, item: HTMLDivElement){
  const rowGap = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-row-gap')),
    rowHeight = parseInt(window.getComputedStyle(grid).getPropertyValue('grid-auto-rows'));

  if(isNaN(rowGap) || isNaN(rowHeight)) {
    return EmptyObject
  } else {
    const rowSpan = Math.ceil((tik4GetEventItemHeight(item) + rowGap) / (rowHeight + rowGap));
    return {
      gridRowEnd: 'span ' + rowSpan
    }
  }
}

function tik4ResizeAllMasonryItems(grid: HTMLDivElement){
  const ret: Map<HTMLDivElement, any> = new Map()

  var allItems = grid.querySelectorAll('.tik4-event, .tik4-chapter, .tik4-extension--between-event');
  // var allItems = grid.querySelectorAll('.tik4-event, .tik4-chapter');

  for(var i=0;i<allItems.length;i++){
    const p = tik4ResizeMasonryItem(grid, allItems[i] as HTMLDivElement);
    ret.set(allItems[i] as HTMLDivElement, p)
  }

  return ret
}

interface MasonryContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

interface MasonryContainerContextType {
  map: Map<HTMLDivElement, any>
  recalculate: () => void
}

const MasonryContainerContext = createContext<MasonryContainerContextType>({
  map: new Map(),
  recalculate: () => {}
})


export function MasonryContainer({children, ...divProps}: MasonryContainerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [needsRecalculate, setNeedsRecalculate] = useState(false)
  const [masonryAttributes, setMasonryAttributes] = useState<Map<HTMLDivElement, any>>(new Map())

  const layout = useLiveblogWrapperLayout();

  const recalculate = useCallback(() => {
    setNeedsRecalculate(true)
  }, [setNeedsRecalculate])

  useEffect(() => {
    recalculate()
  }, [recalculate])

  useEffect(() => {

    if(containerRef.current && layout === 'masonry') {
      recalculate();
      // const ref = containerRef.current
      // const resizeObserver =  window.ResizeObserver ? new ResizeObserver(recalculate) : undefined
      // const mutationObserver = window.MutationObserver ?  new MutationObserver(recalculate) : undefined
      // ref.addEventListener("load", recalculate)
      const interval = setInterval(recalculate, 1000)

      // mutationObserver?.observe(ref, {childList: true, subtree: true, attributes: true})
      // resizeObserver?.observe(ref)

      return () => {
        clearInterval(interval)
        // ref.removeEventListener("load", recalculate);
        // mutationObserver?.disconnect()
        // resizeObserver?.disconnect()
      }
    }
  }, [recalculate, layout])

  useEffect(() => {
    if(needsRecalculate && containerRef.current) {
      const items = tik4ResizeAllMasonryItems(containerRef.current);
      setMasonryAttributes(items)
    }
    setNeedsRecalculate(false)
  }, [needsRecalculate])

  return <MasonryContainerContext.Provider value={{map: masonryAttributes, recalculate}}>
      <div ref={containerRef} {...divProps}>
      {children}
    </div>
  </MasonryContainerContext.Provider>
}

export function useMasonryProperties() {
  const {map} = useContext(MasonryContainerContext)
  const ref = useRef<HTMLDivElement>(null)


  return {
    css: (ref.current && map.get(ref.current)) || {},
    ref
  }
}
