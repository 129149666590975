import {SportMetadata, SportstypeSpec} from "./types";
import {IEvent, IGame, ISportEventContentBlock} from "@webng-types/write-model";
import i18next from "i18next";

export type {
  SportstypeSpec,
  SportstypeEventSpec,
  SportEventPlayerSpec,
  SportEventPlayerId,
  SportEventPlayerSelectSpec,
  SportEventTeamSelectSpec,
  ScoreboardType,
  SportMetadata,
  SportReleaseStage,
  SportDisplayTimeFormat,
  SportDisplayPhaseFormat
} from './types'
export {TeamGameScoreboardDisplayTypes, SetBasedScoreboardDisplayTypes} from './types'


const customSportstypeMap: Record<string,string[]|undefined> = {
  // '55684dece4b03ddac3d474c1': ['_custom_tour_of_britain', '_custom_worldrowing', '_custom_worldrowing_flags'], // Testorga commented out due to console spam with missing translations
  '64632706a356502d9fcbe433': ['_custom_worldrowing', '_custom_worldrowing_flags'],
  '64df28b0ad5c65b8f24fddb7': ['_custom_tour_of_britain']
}

export async function loadMetadata(organizationId?: string): Promise<SportMetadata> {
  const metadata = await import('./metadata');

  if(organizationId && customSportstypeMap[organizationId]) {
    const additionalSportstypes = await Promise.all((customSportstypeMap[organizationId] || []).map(async (sportstype) => {
      return await loadSportstypeSpec(sportstype)
    }))

    return {
      phases: metadata.phases,
      sportstypes: [...metadata.sportstypes, ...additionalSportstypes]
    }
  } else {
    return metadata
  }
}

export async function loadSportstypeSpec(sportstype: string): Promise<SportstypeSpec> {
  return await import(`./sportstype/${sportstype}`).then(i => i.default)
}

export async function legacySportEventInfoConverter(game: IGame, event: IEvent): Promise<ISportEventContentBlock|undefined> {
  const mod = await import('./legacySportEventInfoConverter')
  const sportstype = await import(`./sportstype/${game.sportstype}`).then(i => i.default).catch(() => undefined)
  if(!sportstype) return undefined;
  // legacy conversion always happens in german, since we did not have any international customers
  if(!i18next.hasResourceBundle('de', 'sports')) {
    await i18next.reloadResources('de', 'sports')
  }
  const t = i18next.getFixedT('de', 'sports')

  return mod.legacySportEventInfoConverter(sportstype, t, game, event)
}

export * from './i18nUtils'
export * from './timingUtils'
