import React from "react";
import {useTranslation} from "react-i18next";
import {HtmlView} from "./HtmlView";
import {IRichTextContentBlock} from "@webng-types/write-model";

export function RichTextContentBlockView({block} : {block: IRichTextContentBlock}) {
  const {i18n} = useTranslation()

  return <HtmlView className={`tik4-rich-text tik4-rich-text--${i18n.language}`} html={block.text || ""} />
}
