import { RefObject } from "react"
import {ConsentListener, ConsentProvider} from "@webng/liveblog";
import { firetickarooLiveblogCustomConsent } from "../events";

class EmulatedStorage implements Storage {
  private readonly _storage: Map<string, string> = new Map()

  get length(): number {
    return this._storage.size
  }

  clear(): void {
    this._storage.clear()
  }

  getItem(key: string): string | null {
    return this._storage.get(key) || null;
  }

  key(index: number): string | null {
    throw new Error("key is unsupported")
  }

  removeItem(key: string): void {
    this._storage.delete(key)
  }

  setItem(key: string, value: string): void {
    this._storage.set(key, value)
  }

}

const storage = (typeof window === 'undefined') ? new EmulatedStorage() : (window.localStorage || new EmulatedStorage())
const StorageKey = "tickaroo-embedconsent"

export class CustomConsentProvider implements ConsentProvider {
  private _listeners: ConsentListener[] = [];
  private _el: RefObject<HTMLDivElement> | null = null;
  private readonly _consentMap: Record<string, boolean | undefined> = {}
  private readonly _useCookies: boolean = true;

  constructor(useCookies: boolean = true, el: RefObject<HTMLDivElement> | null) {
    this._useCookies = useCookies;
    this._el = el;
    if (useCookies) {
      try {
        const json = storage.getItem(StorageKey)
        if (json) {
          this._consentMap = JSON.parse(json)
        }
      } catch (e) {
        // ignore
      }
    }
  }

  getConsent(provider: string): boolean {
    return this._consentMap[provider] || false
  }

  protected listerForConsent(event: CustomEvent): void {
    const { provider, consent } = event.detail
    this.setConsent(provider, consent)
  }

  requestConsent(provider: string, consent: boolean) {
    if (this._el && this._el.current && this._el.current.parentElement) {
      firetickarooLiveblogCustomConsent(this._el.current.parentElement, { t: 'a_con', provider: provider, consent: consent })
    }
  }

  setConsent(provider: string, consent: boolean, setConsentState: boolean = false): void {
    this._consentMap[provider] = consent
    if (this._useCookies) {
      try {
        storage.setItem(StorageKey, JSON.stringify(this._consentMap))
      } catch (e) {
        //ignore
      }
    }
    this.fireConsentListener(provider, consent);
  }

  addConsentListener(listener: ConsentListener): void {
    this._listeners = [...this._listeners, listener];
  }

  removeConsentListener(listener: ConsentListener): void {
    this._listeners = this._listeners.filter(l => l !== listener)
  }

  protected fireConsentListener(provider: string, consent: boolean): void {
    this._listeners.forEach(value => value(provider, consent))
  }
}


