import React, {ReactNode} from "react";
import {IGame} from "@webng-types/write-model";
import {useTranslation} from "react-i18next";

interface LiveblogMilestoneListProps {
  game: IGame
  children: ReactNode
}

export function LiveblogMilestoneList({game, children}: LiveblogMilestoneListProps) {
  const {t} = useTranslation("liveblog")
  return <React.Fragment>
    {game.summary?.milestones && game.summary.milestones.refs && game.summary.milestones.refs.length > 0 &&
    <div className="tik4-chapters">
      <div className="tik4-chapters__label">{t('liveblog.milestone_list.headline')}</div>
      {children}
    </div>
    }
  </React.Fragment>
}
