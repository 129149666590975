import {useSportEventSpec} from "../utils/useSportEventSpec";
import {ISportEventContentBlock} from "@webng-types/write-model/index";
import React from "react";

interface EventSportIconProps {
  block: ISportEventContentBlock,
  className?: string
}

export function EventSportIcon({block, className}: EventSportIconProps) {
  const {sportstypeEventSpec} = useSportEventSpec(block.event_type)

  if(sportstypeEventSpec) {
    return <React.Fragment>
      <sportstypeEventSpec.svg className={className} />
    </React.Fragment>
  } else {
    return <React.Fragment />
  }

}
