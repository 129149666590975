import React from "react";
import {IPlayerCardContentBlock, ISportEventPlayer} from "@webng-types/write-model";
import {PlayerCard} from "./components/PlayerCard";

interface PlayerCardContentBlockViewProps {
  block: IPlayerCardContentBlock
}


export function PlayerCardContentBlockView({block}: PlayerCardContentBlockViewProps) {
  const {player} = block

  return player ? <PlayerCard eventPlayer={{player: player} as ISportEventPlayer}  /> : <React.Fragment />
}
