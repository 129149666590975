type IdTokenCallback = () => Promise<string>

interface CurrentUser {
  name: string
  picture?: string
  idTokenCallback: IdTokenCallback
}

let currentUser: CurrentUser|undefined = undefined

function validateCurrentUserObject(currentUser: Partial<CurrentUser>): currentUser is CurrentUser  {
  if(typeof currentUser.name !== 'string' || currentUser.name.length === 0) {
    throw new Error("currentUser.name must be a string");
  }
  if(typeof currentUser.idTokenCallback !== 'function') {
    throw new Error("currentUser.idTokenCallback must be a function");
  }

  return true;
}

export function setCurrentUser(inCurrentUser: Partial<CurrentUser>|undefined) {
  if(inCurrentUser) {
    if (validateCurrentUserObject(inCurrentUser)) {
      currentUser = inCurrentUser;
      triggerCurrentUserListener(currentUser);
    }
  } else {
    currentUser = undefined;
    triggerCurrentUserListener(currentUser);
  }
}

export function getCurrentUser(): CurrentUser|undefined {
  return currentUser;
}


type CurrentUserListener = (currentUser: CurrentUser|undefined) => void
let listeners: CurrentUserListener[] = [];

export function addCurrentUserListener(listener: CurrentUserListener) {
  listeners = [...listeners, listener];
}

export function removeCurrentUserListener(listener: CurrentUserListener) {
  listeners = listeners.filter(l => l !== listener)
}

function triggerCurrentUserListener(currentUser: CurrentUser|undefined) {
  listeners.forEach(value => value(currentUser))
}


type OptionalTriggerFunction = (() => void) | undefined

export let triggerLogin: OptionalTriggerFunction = undefined
export let triggerLogout: OptionalTriggerFunction | undefined = undefined

export function setTriggerLoginFunction(f: OptionalTriggerFunction) {
  triggerLogin = f;
}
