import React from "react";
import {Shield3} from "@webng/icons-common";
import {Shield} from "react-feather";
import {useTranslation} from "react-i18next";
import { DprApiImage } from "../ApiImage";

export function EventTeamIconPlaceholder({className, name, length=1} : {className?:string, name?:string | undefined, length?:1|2|3} )  {
  const formatted_name = name ? name.replace(/\s+/g, '').substring(0, length) : '';

  return <div className={`tik4-ti-ph ${className}`}>
    { name
      ?
      <>
        <Shield3 className="tik4-ti-ph__svg" />
        <div className="tik4-ti-ph__i">{formatted_name}</div>
      </>
        :
        <Shield className="tik4-ti-ph__svg" />
    }
  </div>
}


export default function EventTeamIcon({className, placeholderClassName, image, name, size=32} : {className?:string, placeholderClassName?:string, image?:string, name?:string, size?:number}) {
  const {t} = useTranslation('liveblog');

  if (image) {
    // return <ApiImage className={className} src={teamImage.src.url} srcSet={teamImage.srcSet} height={teamImage.src.height} width={teamImage.src.width}/>
    return <DprApiImage className={className} image={image} height={size} width={size} fit="contain" alt={t('liveblog.sport.scoreboard.img.alt', {name: name})}/>
  } else {
    return <EventTeamIconPlaceholder className={placeholderClassName} name={name} />
  }

}
