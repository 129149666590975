import React, {useState, ReactNode} from "react";
import {useTranslation} from "react-i18next";
import useBreakpoints from "@webng/react-app-common/src/utils/useBreakpoints";
import { SlickNextArrow, SlickPrevArrow } from "./content/MultiMediaSlider";
import { SliderWrapper } from "./ReactSlickLazy";



interface LiveblogHighlightSliderSummaryProps {
  highlightCount: number
  children: ReactNode
}

export function LiveblogHighlightSliderSummary({highlightCount, children}: LiveblogHighlightSliderSummaryProps) {
  const {t} = useTranslation("liveblog")
  const [lastSlides, setLastSlides] = useState<boolean>();

  const {ref: sizeRef, breakpoint: breakpoint} = useBreakpoints([
    ["", 576],
    ["sm", 768],
    ["md", 992],
    ["lg", 1200],
    ["xl", Infinity]
  ]);

  let slidesToShow, slidesToScroll;
  switch (breakpoint) {
    case "sm":
      slidesToShow = 2;
      slidesToScroll = 2;
      break;
    case "md":
      slidesToShow = 3;
      slidesToScroll = 3;
      break;
    case "lg":
      slidesToShow = 4;
      slidesToScroll = 4;
      break;
    case "xl":
      slidesToShow = 5;
      slidesToScroll = 5;
      break;

    default:
      slidesToShow = 1;
      slidesToScroll = 1;
      break;
  }

  if (!highlightCount) {
    return <></>;
  }

  // limit to highlightCount max;
  slidesToShow = highlightCount && slidesToShow > highlightCount ? highlightCount : slidesToShow;

  const possibleLastIndex =  highlightCount - slidesToShow;

  const settings = {
    adaptiveHeight: false,
    infinite: false,
    speed: 150,
    slidesToShow: slidesToShow,
    slidesToScroll: slidesToScroll,
    className: `tik4-sl tik4-hss__sl ${lastSlides ? 'tik4-hss__sl--last-slides' : ''}`,
    nextArrow: <SlickNextArrow />,
    prevArrow: <SlickPrevArrow />,
    dots: true,
    appendDots: (dots:React.ReactNode) => <ul className="tik4-sl__d">{dots}</ul>,
        dotsClass: "tik4-sl__d",
    customPaging: (i:number) => (
      <button className="tik4-sl__d__d">
        <div className="tik4-sl__d__d__i">{i+1}</div>
      </button>
    ),
    beforeChange: (current:any, next:any) => {
      setLastSlides(next >= possibleLastIndex);
    }
  }

  return <React.Fragment>
    {highlightCount && highlightCount > 0 &&
    <div className="tik4-hss" ref={sizeRef} style={{"--t4-hss-sts": slidesToScroll} as React.CSSProperties}>
      <div className="tik4-hss__label"><span className="tik4-hs__label__t">{t('liveblog.highlight_summary.headline')}</span></div>
      <SliderWrapper {...settings} >
        {children}
      </SliderWrapper>
    </div>
    }
  </React.Fragment>
}
