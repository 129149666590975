import { parseJSON, PropsParser, requireString, parseBoolean } from "@webng/validations";
import { LiveblogThemeConfiguration, LiveblogWidgetInitialData } from "@webng-types/embedjs";
import {
  LiveblogCoreApi,
  LiveblogCoreWidgetQueryProps,
  LiveblogCoreWidgetQueryPropsParser
} from "../liveblog-core/LiveblogCoreWidgetProps";


export interface LiveblogWidgetTagProps {
  liveblogId: string
  clientId: string
  css: string
  themeId: string
  initialData: LiveblogWidgetInitialData
  customerConsent: boolean
  disableTracking: boolean
}

export interface LiveblogWidgetQueryProps extends LiveblogCoreWidgetQueryProps {
}

export interface LiveblogWidgetProps extends LiveblogWidgetTagProps, LiveblogWidgetQueryProps {
  overrideConfiguration: Partial<LiveblogThemeConfiguration>
}


export const liveblogWidgetTagPropsParser: PropsParser<LiveblogWidgetTagProps> = {
  clientId: i => requireString("clientId", i),
  liveblogId: i => requireString("liveblogId", i),
  initialData: i => parseJSON("initialData", i, {}),
  css: i => i || "",
  themeId: i => i || "",
  customerConsent: i => parseBoolean('customerConsent', i, false),
  disableTracking: i => parseBoolean('disableTracking', i, false)
}

export const LiveblogWidgetQueryPropsParser: PropsParser<LiveblogWidgetQueryProps> = {
  ...LiveblogCoreWidgetQueryPropsParser
}

export interface LiveblogApi extends LiveblogCoreApi {

}
