import React, {HTMLAttributes} from "react";
import {EmbedProvider, getProvider} from "./EmbedProviders";
import {DisabledWebEmbedView} from "./DisabledWebEmbedView";
import {SimpleWebEmbedView} from "./SimpleWebEmbedView";
import {ConsentWrapper} from "./ConsentWrapper";
import {ProviderConstraints, ProviderConstraintValue} from "./ProviderConstraintAndConsent";
import {useWebEmbedProviderConstraint} from "../LiveblogRenderContext";
import {OEmbed} from "./OEmbed";
import {ErrorBoundary} from "@webng/react-app-common";

interface WebEmbedProps extends HTMLAttributes<HTMLDivElement> {
  blockId: string
  oembed: OEmbed
  locale: string
  providerConstraints?: ProviderConstraints
}

interface WebEmbedSwitchProps {
  blockId: string
  oembed: OEmbed
  locale: string
  Provider: EmbedProvider
  constraint: ProviderConstraintValue
}

function WebEmbedSwitcher({blockId, oembed, locale, constraint, Provider}: WebEmbedSwitchProps) {
  switch(constraint) {
    case "disabled":
      return <DisabledWebEmbedView />
    case "hide":
      return <React.Fragment/>
    case "simple":
      return <SimpleWebEmbedView oembed={oembed} />
    case "consent":
      return <ConsentWrapper blockId={blockId} oembed={oembed}>
        <Provider oembed={oembed} locale={locale} />
      </ConsentWrapper>
    case "full":
    case undefined:
    default:
      return <Provider oembed={oembed} locale={locale} />
  }
}



export function WebEmbed({oembed, blockId, locale, ...attributes}: WebEmbedProps) {
  const Provider = getProvider(oembed)
  const constraint = useWebEmbedProviderConstraint(oembed.provider_name!);

  return <ErrorBoundary>
    <div {...attributes}>
      <WebEmbedSwitcher blockId={blockId} oembed={oembed} locale={locale} constraint={constraint} Provider={Provider} />
    </div>
  </ErrorBoundary>
}
