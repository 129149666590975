import {AdManager} from "./AdManager";
import {IEvent} from "@webng-types/write-model";
import React, {useEffect, useMemo, useState} from "react";
import {EventExtensionComponent, EventExtensionProps} from "@webng/liveblog/src";
import {InlineAdView} from "./InlineAdView";




export function useAdExtension(adManager: AdManager|undefined, rows: IEvent[]) {

  useEffect(() => {
    if(adManager) {
      adManager.update(rows)
    }
  }, [adManager, rows])

  return useMemo(() => {
    if(adManager) {
      const AdExtension: EventExtensionComponent = ({event}: EventExtensionProps) => {
        const [html, setHtml] = useState<string>()

        useEffect(() => {
          return adManager.subscribe(event, setHtml)
        }, [event])

        if (html) {
          return <InlineAdView html={html} position={adManager.position} />
        } else {
          return null
        }
      }

      return {
        [adManager.position]: AdExtension
      }
    } else {
      return {}
    }
  }, [adManager])
}
