import React from "react";
import {DefaultErrorBoundary} from "@webng/react-app-common";
import {notifyError} from "../analytics/notifyError";


export class EmbedJsErrorBoundary extends DefaultErrorBoundary {
  componentDidCatch(error: Error, info: React.ErrorInfo) {
    super.componentDidCatch(error, info)

    notifyError(error, {componentStack: info.componentStack || ""})
  }
}
