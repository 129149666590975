import React from "react";
import {EmbedProviderProps} from "./EmbedProviders";

export function DefaultProvider({oembed}: EmbedProviderProps) {
  return <div className={`tik4-we-default tik4-we-default--${oembed.type} tik4-we-default--${oembed.provider_name?.toLowerCase()}`}>
    <div className="tik4-we-default__container" dangerouslySetInnerHTML={{__html: oembed.html || ""}} />
  </div>
}

export default DefaultProvider
