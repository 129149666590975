import React, {useEffect} from "react";
import {EmbedProviderProps} from "./EmbedProviders";
import ExecutableProvider from "./ExecutableProvider";

export function Apester(props: EmbedProviderProps) {

  useEffect(() => {
    if((window as any).APESTER) {
      (window as any).APESTER.reload();
    }
  })

  return <ExecutableProvider {...props} />
}

export default Apester
