import {startCustomElementSetup} from "./customElementSetup";
import {fireInitEvent} from "./events";

import '../../css/tik4.css'
import {ensureCss} from "../utils/styleTags";

import * as api from './api'
import {setupCommonComponents} from "@webng/react-app-common";
import {EmbedJsErrorBoundary} from "./EmbedJsErrorBoundary";
import {notifyError} from "../analytics/notifyError";
import {setupGlobalErrorHandler} from "../analytics/setupGlobalErrorHandler";
import {tracedFetch} from "../utils/tracedFetch";
import globalSettings, {findClientScriptTag} from "./globalSettings";

declare var IS_PRODUCTION: boolean;

function _init() {
  setupGlobalErrorHandler()

  setupCommonComponents({
    ErrorBoundary: EmbedJsErrorBoundary,
    notifyError,
    fetchFn: tracedFetch
  })

  fireInitEvent()

  ensureCss("tik4.css")

  startCustomElementSetup()
}

if(globalSettings.localDev && IS_PRODUCTION) {
  // Local Dev mode, activate with #tickaroo_local_dev=true
  const localScript = findClientScriptTag()
  const newScript = document.createElement('script')
  newScript.async = true
  newScript.src = "http://localhost:8040/webng/embedjs/tik4.js"
  document.head.appendChild(newScript)
  if(localScript) {
    localScript.remove()
  }
} else {

  (window as any).tik4 = api

  _init();
}
