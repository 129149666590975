import {IEvent} from "@webng-types/write-model";
import {AdManagerSubscriber} from "./AdManager";
import {EventExtension} from "@webng/liveblog/src";



export abstract class AbstractAdManager {
  private readonly _subscribers: Record<string, AdManagerSubscriber[]>
  public readonly position: keyof EventExtension

  protected constructor(position: keyof EventExtension = 'EventTop') {
    this._subscribers = {}
    this.position = position
  }

  subscribe(event: IEvent, subscriber: (html: string|undefined) => void) {
    this._subscribers[event.local_id!] ||= []
    const index = this._subscribers[event.local_id!].push(subscriber) - 1

    return () => {
      this._subscribers[event.local_id!].splice(index, 1)
    }
  }

  notify(event: IEvent, html: string|undefined) {
    const callbacks = this._subscribers[event.local_id!]
    if(callbacks) {
      callbacks.forEach(callback => callback(html))
    }
  }

  abstract update(rows: IEvent[]): void;
}


