import React, {HTMLAttributes, useState} from "react";
import {ChevronDown} from "react-feather";


interface ExpandableContentProps extends HTMLAttributes<HTMLDivElement> {
  header: React.ReactNode
}



export function ExpandableContent({header, className, children, ...attributes} : ExpandableContentProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return <div className={`tik4-ec ${className ? className : ''}`} {...attributes}>
    <button className="tik4-ec__h" onClick={() => setIsExpanded(!isExpanded)} type="button">
      <div className="tik4-ec__h__c">
        {header}
      </div>
      <div className="tik4-ec__h__i">
        <ChevronDown className={`tik4-ec__h__ch tik4-icon ${isExpanded ? 'tik4-ec__h__ch--ex' : ''}`} />
      </div>
    </button>
    { isExpanded &&
      <div className="tik4-ec__c">
        {children}
      </div>
    }

  </div>
}
