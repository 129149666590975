import {debugEvent, notifyError} from "../analytics/notifyError";

export const tracedFetch: typeof window.fetch = function tracedFetch(input, init) {
  let method: string = 'GET', url: string = ''

  if (input && input instanceof Request) {
    url = input.url
    if (init && 'method' in init) {
      method = init.method || 'GET'
    } else if (input && 'method' in input) {
      method = input.method
    }
  } else if(input && input instanceof URL) {
    url = input.toString()
    if (init && 'method' in init) {
      method = init.method || 'GET'
    }
  } else {
    url = input
    if (init && 'method' in init) {
      method = init.method || 'GET'
    }
  }

  // debugEvent(`fetchStart ${method} ${url}`, {})

  const ts = new Date().getTime();

  try {
    return new Promise((resolve, reject) => {
      window.fetch(input, init)
        .then(r => {
          debugEvent('fetchSuccess', {
            method,
            url,
            status: r.status.toString(),
            duration: (new Date().getTime() - ts).toString()
          })
          resolve(r)
        })
        .catch(e => {
          debugEvent('fetchError', {
            method,
            url,
            duration: (new Date().getTime() - ts).toString()
          })
          reject(e)
        })
    })
  } catch(e) {
    debugEvent('fetchFailed', {
      error: e.toString(),
      method,
      url,
      duration: (new Date().getTime() - ts).toString()
    })

    notifyError(e, {
      method: method,
      url: url
    })
    throw e
  }
}
