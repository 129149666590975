import errorStackParser from 'error-stack-parser'
import {notifyError} from "./notifyError";

function notifyIfTickaroo(error: any) {
  if(error?.stack) {
    const stack = errorStackParser.parse(error)
    // only send error if the topmost stack entry is a tickaroo script
    if(stack.length > 0 && stack[0].fileName) {
      if(stack[0].fileName.includes(".tickaroo.com/") || stack[0].fileName.includes("@webng/")) {
        notifyError(error);
      }
    }
  }
}

function globalErrorHandler(event: ErrorEvent) {
  notifyIfTickaroo(event.error)
}

function globalRejectionHandler(event: PromiseRejectionEvent) {
  notifyIfTickaroo(event.reason)
}

export function setupGlobalErrorHandler() {
  window.addEventListener('error', globalErrorHandler);
  window.addEventListener('unhandledrejection', globalRejectionHandler)
}
