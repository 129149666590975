/* Test cases for relative urls:
valid:
/
/about
/contact-us
/products/item1
/services/service-123
/2024/04/sample-post
/files/download/setup.exe
/user/profile
/search?q=books
/path/to/resource/with/longer/path
/assets/images/logo.png
/en-us/default.aspx
/docs/guide/en/part-1
/archive/2024/03/
/temp%20files/
/category/subcategory/page.html
/feed.xml
/path-with-dash/
/path_with_underscore/
/percent%20encoded%20space

not valid:
http://www.example.com/about
https://example.com/contact
//cdn.example.com/lib.js 
/about us/ 
?name=value
#section
mailto:example@example.com
javascript:alert('XSS')
./../
C:\Directory\file.txt
/path/to/url/with/"quotes"
/path/to/url/with/<tags>
 /leading/spaces 
/trailing/spaces 
//
//protocal.com/relative/url
@file
*/
export function isRelativeUrl(url: string): boolean {
		return new RegExp("^/(?!/)[^\\s]*$").test(url);
}