import {TickarooLiveblogCoreElement} from "./TickarooLiveblogCoreElement";
import {TickarooLiveblogElement} from "./TickarooLiveblogElement";

export function startCustomElementSetup() {

  if(!customElements.get('tickaroo-liveblog-core')){
    window.customElements.define("tickaroo-liveblog-core", TickarooLiveblogCoreElement)
  }

  if(!customElements.get('tickaroo-liveblog')){
    window.customElements.define("tickaroo-liveblog", TickarooLiveblogElement)
  }
}
