import React from "react";
import {IEvent} from "@webng-types/write-model";
import {Hexagon} from "react-feather";
import {useMasonryProperties} from "./MasonryContainer";

interface LiveblogMilestoneRowProps {
  event: IEvent,
}


export function LiveblogMilestoneRow({event}: LiveblogMilestoneRowProps) {
  const {ref, css} = useMasonryProperties()

  return <div ref={ref} style={css} className="tik4-chapter" data-tickaroo-event-id={event.local_id}>
    <h2 className="tik4-chapter__headline">
      <Hexagon className="tik4-chapter-icon tik4-chapter__headline__icon"/>
      <span className="tik4-chapter__headline__text">{event.headline}</span>
    </h2>
  </div>
}
