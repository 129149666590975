import {IEvent, IGame, IGameShowResponse} from "@webng-types/write-model";
import {Reducer} from "react";

export interface LiveblogData {
  game: IGame
  events: Record<string, IEvent | undefined>
  pendingUpdate?: IGameShowResponse
}

interface InitialLoadAction {
  type: 'init',
  response: IGameShowResponse
}

interface ApplyUpdateAction {
  type: 'applyUpdate',
  response: IGameShowResponse
}

interface SetPendingUpdateAction {
  type: 'setPendingUpdate',
  response: IGameShowResponse
}

interface ApplyPendingUpdateAction {
  type: 'applyPendingUpdate',
}

interface MoreLoadAction {
  type: 'more',
  response: IGameShowResponse
}

export type LiveblogDataAction = InitialLoadAction | ApplyUpdateAction | SetPendingUpdateAction | ApplyPendingUpdateAction | MoreLoadAction
export type LiveblogDataReducer = Reducer<LiveblogData, LiveblogDataAction>

function unknownAction(): never {
  throw new Error("Unknown action")
}

function updateGameEvents(game: IGame, newGame: IGame, updateGame: boolean = true): IGame {
  let newEvents = newGame.events || []
  let oldEvents = game.events || []

  let eventMap = updateEventsMap({}, oldEvents)
  eventMap = updateEventsMap(eventMap, newEvents)

  const events = Object.values(eventMap).sort((a, b) => {
    if(a && b) {
      const s = b.sort!.localeCompare(a.sort!)
      if (s === 0) {
        return b.local_id!.localeCompare(a.local_id!)
      } else {
        return s
      }
    } else if(a) {
      return 1
    } else if(b) {
      return -1
    } else {
      return 0
    }
  } ).filter((e) => e && e.local_status === 0) as IEvent[]

  if (updateGame) {
    return {
      ...newGame,
      events
    }
  } else {
    return {
      ...game,
      events
    }
  }
}

function updateEventsMap(events: Record<string, IEvent | undefined>, newEvents: IEvent[]) {
  const newEventsMap: Record<string, IEvent | undefined> = {}
  newEvents.forEach(value => {
    const oldEvent = events[value.local_id!]
    if (!oldEvent || value.version! > oldEvent.version!) {
      newEventsMap[value.local_id!] = value
    }
  })

  return Object.assign({}, events, newEventsMap)
}

export function liveblogDataReducer(state: LiveblogData, action: LiveblogDataAction): LiveblogData {
  switch (action.type) {
    case "init":
      return {
        ...state,
        game: action.response.game!,
        events: updateEventsMap({}, action.response.events || []),
      }
    case "applyUpdate":
      return {
        ...state,
        game: updateGameEvents(state.game, action.response.game!),
        events: updateEventsMap(state.events, action.response.events || [])
      }
    case "setPendingUpdate":
      return {
        ...state,
        pendingUpdate: action.response
      }
    case "applyPendingUpdate":
      if(state.pendingUpdate) {
        return {
          ...state,
          game: updateGameEvents(state.game, state.pendingUpdate.game!),
          events: updateEventsMap(state.events, state.pendingUpdate.events || []),
          pendingUpdate: undefined
        }
      } else {
        return state
      }
    case "more":
      return {
        ...state,
        game: updateGameEvents(state.game, action.response.game!, false),
        events: updateEventsMap(state.events, action.response.events || [])
      }
    default:
      return unknownAction()
  }
}
