import {IGame} from "@webng-types/write-model";

export function findLoadMoreTopEventId(game: IGame|undefined): string|undefined {
  if (game && game.events && game.events.length > 0) {
    const currentFirstEventId = game.events[0].local_id!
    const firstEventId = game.summary?.filtered_first_event?.local_id || game.summary?.first_event?.local_id
    if (currentFirstEventId !== firstEventId) {
      return currentFirstEventId
    }
  }
  return undefined
}

export function findLoadMoreBottomEventId(game: IGame|undefined): string|undefined {
  if (game && game.events && game.events.length > 0) {
    const currentLastEventId = game.events[game.events.length - 1].local_id!
    const lastEventId = game.summary?.filtered_last_event?.local_id || game.summary?.last_event?.local_id
    if(currentLastEventId !== lastEventId) {
      return currentLastEventId
    }
  }
  return undefined
}
