import {
  IAbstractContentBlock,
  IEvent,
  IHeadlineContentBlock,
  IMultiMediaContentBlock, IRichTextContentBlock,
  IWebEmbedContentBlock
} from "@webng-types/write-model";

import attrsToHTML from '@webng/tik-attributed-text/to-html';

function legacyConvertRichText(text: string, attrsJson?: string) {
  if(attrsJson) {
    try {
      const attrs = JSON.parse(attrsJson)
      if (attrs && attrs.attrs) {
        text = attrsToHTML(text, attrs.attrs, {replaceNewLinesWithBrTag: true, headlineSpanTag: "h2"})
      }
    } catch(e) {
      text = attrsToHTML(text, [], {replaceNewLinesWithBrTag: true})
    }
  } else {
    text = attrsToHTML(text, [], {replaceNewLinesWithBrTag: true})
  }

  return text
}

export function legacyEventInfoToContentsConverter(event: IEvent) {
  const contents: IAbstractContentBlock[] = []

  if (event.headline) {
    const headlineBlock: IHeadlineContentBlock = {
      _type: "Tik::Model::Content::HeadlineContentBlock",
      local_id: event.local_id + "-legacyHeadline",
      text: event.headline,
      level: 0
    };
    contents.push(headlineBlock);
  }

  if (event.event_info && event.event_info.title) {
    const textBlock: IRichTextContentBlock = {
      _type: "Tik::Model::Content::RichTextContentBlock",
      local_id: event.local_id + "-legacyRichText",
      text: legacyConvertRichText(event.event_info.title || "", event.event_info.attributes_json)
    }
    contents.push(textBlock);
  }

  if (event.media && event.media.length > 0) {
    const mediaBlock: IMultiMediaContentBlock = {
      _type: "Tik::Model::Content::MultiMediaContentBlock",
      local_id: event.local_id + "-legacyMultiMedia",
      items: event.media
        .filter(value => value.local_status === 0)
        .map(value => ({
          _type: value.subtype === 'v' ? "Tik::Model::Content::VideoMediaContent" : "Tik::Model::Content::ImageMediaContent",
          media_local_id: value.local_id,
          credit: value.credit,
          title: value.title
        })
      )
    }
    contents.push(mediaBlock);
  }

  if (event.event_info && event.event_info.web_embed_urls) {
    event.event_info.web_embed_urls.forEach((url, index) => {
      const webBlock: IWebEmbedContentBlock = {
        _type: "Tik::Model::Content::WebEmbedContentBlock",
        local_id: event.local_id + "-legacyWebEmbed-" + index,
        url: url
      }
      contents.push(webBlock);
    })
  }

  return contents;
}

