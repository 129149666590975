import React, {useMemo, useRef} from "react";

import {IEvent, IGame} from "@webng-types/write-model";
import {getHighlightPostText} from "./utils/eventUtils";
import {useNiceDateTime} from "@webng/react-app-common/src";
import {OnSummaryItemClickHandler} from "./Liveblog";

import {DprApiImage} from "./ApiImage";


interface LiveblogHighlightSliderSummaryItemProps {
  game: IGame
  event: IEvent
  onClick?: OnSummaryItemClickHandler
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void
}

export const LiveblogHighlightSliderSummaryItem = React.forwardRef<HTMLHeadingElement, LiveblogHighlightSliderSummaryItemProps>(function LiveblogHighlightSliderSummaryItemImp({event, game, onClick, onKeyDown}, ref) {
  const {date, time} = useNiceDateTime(event.created_at);
  const keyDownPosition = useRef<string|undefined>();
  const editor = useMemo(() => {
    return event.editor || ((event.user_id && game.editors) ? game.editors?.find((m) => m._id === event.user_id): undefined)
  }, [event.editor, event.user_id, game.editors])

  function handlePointerDown(e: React.PointerEvent<HTMLElement>) {
    keyDownPosition.current = `${e.clientX}:${e.clientY}`;
  }

  function handlePointerUp(e: React.PointerEvent<HTMLElement>) {
    if(onClick && keyDownPosition.current && keyDownPosition.current === `${e.clientX}:${e.clientY}`) {
      onClick(event);
    }
    keyDownPosition.current = undefined;
  }

  return <div className="tik4-hss__i" ref={ref} role="button" onPointerDown={handlePointerDown} onPointerUp={handlePointerUp} onKeyDown={onKeyDown} tabIndex={0}>
    {/* item header */}
    <div className="tik4-hss__i__h">

      {/* datetime */}
      <div className="tik4-hss__dt">
        <div className="tik4-hss__dt__d">{date}</div>
        <div className="tik4-hss__dt__t">{time}</div>
      </div>

      {/* author */}
      {editor &&
        <div className="tik4-hss__a">
          <div className="tik4-hss__a__w">
            <div className="tik4-hss__a__n" title={editor.name}>
              {editor.name}
            </div>
            { editor.image &&
              <div className="tik4-hss__a__t">
                <DprApiImage
                    className="tik4-hss__a__t__img"
                    alt={editor.name}
                    image={editor.image}
                    width={32}
                    height={32}
                    fit="cover"
                    />
              </div>
            }
          </div>
        </div>
      }
    </div>

    {/* content */}
    <div className="tik4-hss__c">
      { getHighlightPostText(event) }
    </div>
  </div>
})
