import React, {useCallback} from "react";
import {EmbedProviderProps} from "./EmbedProviders";
import {ensureScriptTag} from "./tagManager";

export function Facebook({oembed, locale}: EmbedProviderProps) {

  const ref = useCallback((element: HTMLDivElement|null) => {
    if(element) {
      if ((window as any).FB) {
        (window as any).FB.XFBML.parse(element);
      } else {
        if(locale === 'de') {
          ensureScriptTag({src: 'https://connect.facebook.net/de_DE/sdk.js', async: true, defer: true, crossOrigin: "anonymous", referrerPolicy: "no-referrer"})
        } else {
          ensureScriptTag({src: 'https://connect.facebook.net/en_US/sdk.js', async: true, defer: true, crossOrigin: "anonymous", referrerPolicy: "no-referrer"})
        }

        const oldFbAsyncInit = (window as any).fbAsyncInit;
        (window as any).fbAsyncInit = function () {
          if(oldFbAsyncInit) {
            oldFbAsyncInit();
          } else {
            (window as any).FB.init({
              xfbml: false,
              version: 'v20.0'
            });
          }

          (window as any).FB.XFBML.parse(element);
        };
      }
    }
  }, [locale])

  return <div className="tik4-we-facebook" ref={ref} dangerouslySetInnerHTML={{__html: oembed.html || ""}} />
}

export default Facebook
