import {useEffect, useState} from "react";
import {loadSportstypeSpec, SportstypeSpec} from "@webng/sports/src";

export function useSportstypeSpec(sportstype: string | undefined) {
  const [data, setData] = useState<SportstypeSpec>()
  const [state, setState] = useState<"loading"|"error"|"success">("loading")

  useEffect(() => {
    setData(undefined)
    if (sportstype) {
      loadSportstypeSpec(sportstype).then(s => {
        setData(s);
        setState("success");
      }).catch(() => {
        setState("error")
      })
    }
  }, [sportstype])

  return {sportstypeSpec: data, state}
}

export function useSportEventSpec(eventId: string | undefined) {
  const [sportstypeId, sportstypeEventId] = eventId ? eventId.split(".") : [undefined, undefined]

  const {sportstypeSpec} = useSportstypeSpec(sportstypeId)

  const sportstypeEventSpec = sportstypeSpec?.events?.find(e => e.id === sportstypeEventId)

  return {sportstypeSpec, sportstypeEventSpec}
}
