import {useEnvironment} from "@webng/react-app-common";
import React from "react";
import {parseSrcset, SrcSetDefinition, stringifySrcset} from "srcset";
import { exactSizeImageUrl } from "./converter/imageUrl";

export function expandImageUrl(host: string, url: string|undefined) {
  if(url) {
    if (!url.includes("//")) {
      return `${host}${url}`
    } else {
      return url;
    }
  } else {
    return undefined;
  }
}

export interface ApiImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  srcDef?: SrcSetDefinition[]
}

export function expandImageSet(host: string, srcSetDefinitions: SrcSetDefinition[]): SrcSetDefinition[] {
  return srcSetDefinitions.map(def => ({
    ...def,
    url: expandImageUrl(host, def.url) || ""
  }))
}

export function ApiImage({src, srcDef, srcSet, alt, ...props}: ApiImageProps) {
  const env = useEnvironment();

  const expSrc = expandImageUrl(env.apiHost, src);

  let expSrcSet = undefined;
  if(srcDef) {
    expSrcSet = stringifySrcset(srcDef);
  } else if(srcSet) {
    expSrcSet = stringifySrcset(expandImageSet(env.apiHost, parseSrcset(srcSet)));
  }

  return <img src={expSrc} srcSet={expSrcSet} alt={alt} {...props} />
}


interface DprApiImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  image:string,
  width:number,
  height:number,
  fit:"contain"|"cover"|undefined
}

export function DprApiImage({image, width, height, fit="contain", ...attributes} : DprApiImageProps) {
  const image1x = exactSizeImageUrl(image, {width: width, height: height, fit: fit, format: "webp"})
  const image2x = exactSizeImageUrl(image, {width: width*2, height: height*2, fit: fit, format: "webp"})
  const image3x = exactSizeImageUrl(image, {width: width*3, height: height*3, fit: fit, format: "webp"})
  const image4x = exactSizeImageUrl(image, {width: width*4, height: height*4, fit: fit, format: "webp"})

  const src = image1x
  const srcSet = `
    ${image4x.url} 4x,
    ${image3x.url} 3x,
    ${image2x.url} 2x,
    ${image1x.url} 1x
    `
  return <ApiImage src={src.url} srcSet={srcSet} width={src.width} height={src.height} {...attributes} />
}
