// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
import {useEffect, useMemo, useRef} from "react";

export type Procedure = (...args: any[]) => void;

export type Options = {
  isImmediate: boolean,
}

export function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = 50,
  options: Options = {
    isImmediate: false
  },
): (this: ThisParameterType<F>, ...args: Parameters<F>) => void {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;

  return function(this: ThisParameterType<F>, ...args: Parameters<F>) {
    const context = this;

    const doLater = function() {
      timeoutId = undefined;
      if (!options.isImmediate) {
        func.apply(context, args);
      }
    }

    const shouldCallNow = options.isImmediate && timeoutId === undefined;

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(doLater, waitMilliseconds);

    if (shouldCallNow) {
      func.apply(context, args);
    }
  }
}

export function useDebouncedFunction<F extends Procedure>(
  f: F,
  waitMilliseconds = 50,
  options: Options = {
    isImmediate: false
  }): (this: ThisParameterType<F>, ...args: Parameters<F>) => void {

  const debouncedFunction = useRef<F>()

  useEffect(() => {
    debouncedFunction.current = f

    return () => debouncedFunction.current = undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return useMemo(() => debounce(function (this: ThisParameterType<F>, ...args: any[]) {
    debouncedFunction.current?.apply(this, args)
  }, waitMilliseconds, options), [waitMilliseconds, options]);
}



export type AsyncProcedure<T> = (...args: any[]) => Promise<T>;


export function debounceAsync<T, F extends AsyncProcedure<T>>(
  func: F,
  waitMilliseconds = 50,
  options: Options = {
    isImmediate: false
  },
): (this: ThisParameterType<F>, ...args: Parameters<F>) => Promise<T> {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  let resolvePromise: ((res: T | PromiseLike<T>) => void) | undefined;
  let rejectPromise: ((reason?: any) => void) | undefined;

  return function(this: ThisParameterType<F>, ...args: Parameters<F>): Promise<T> {
    const context = this;

    return new Promise<T>((resolve, reject) => {
      const doLater = async function() {
        timeoutId = undefined;
        if (!options.isImmediate) {
          try {
            const result = await func.apply(context, args);
            resolve(result);
            if (resolvePromise) resolvePromise(result);
          } catch (error) {
            reject(error);
            if (rejectPromise) rejectPromise(error);
          }
        }
      };

      const shouldCallNow = options.isImmediate && timeoutId === undefined;

      if (timeoutId !== undefined) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(doLater, waitMilliseconds);

      if (shouldCallNow) {
        (async () => {
          try {
            const result = await func.apply(context, args);
            resolve(result);
          } catch (error) {
            reject(error);
          }
        })();
      } else {
        resolvePromise = resolve;
        rejectPromise = reject;
      }
    });
  };
}

export function useDebouncedAsyncFunction<T, F extends AsyncProcedure<T>>(
  f: F,
  waitMilliseconds = 50,
  options: Options = {
    isImmediate: false
  }
): (this: ThisParameterType<F>, ...args: Parameters<F>) => Promise<T> {

  const debouncedFunction = useRef<F>();

  useEffect(() => {
    debouncedFunction.current = f;

    return () => { debouncedFunction.current = undefined; };
  }, [f]);

  return useMemo(() => debounceAsync(function(this: ThisParameterType<F>, ...args: any[]): Promise<T> {
    if (debouncedFunction.current) {
      return debouncedFunction.current.apply(this, args);
    }
    return Promise.reject("No function");
  }, waitMilliseconds, options), [waitMilliseconds, options]);
}
