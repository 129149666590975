import {ISportEventPlayer} from "@webng-types/write-model";
import React from "react";
import {DprApiImage} from "../../ApiImage";
import {NiceDateTime} from "../../NiceDateTime";
import {Facebook, Instagram} from "react-feather";
import {XSocial} from "@webng/icons-common";

import {useTranslation} from "react-i18next";
import {SportEventContentViewPlayerItem} from "./PlayerView";

interface SportEventContentViewPlayerProps {
  eventPlayer: ISportEventPlayer,
}


export function PlayerCard({eventPlayer}: SportEventContentViewPlayerProps) {
  const {t, i18n} = useTranslation('liveblog');

  // const playerImage = eventPlayer.player?.image ? exactSizeImageUrl(eventPlayer.player?.image, {width: 64, height: 64}) : undefined

  return <React.Fragment key={eventPlayer.event_player_type}>
    <div className={`tik4-plcard ${ eventPlayer.event_player_type ? `tik4-plcard--${eventPlayer.event_player_type}` : ''}`}>

      { eventPlayer.player?.image &&
        <div className="tik4-plcard__i">
          {/* TODO:Andi! SourceImage */}
          {/* TODO:Timo! SourceImage */}
          <DprApiImage
            className="tik4-plcard__i__img"
            image={eventPlayer.player.image}
            width={300}
            height={300}
            fit='contain'
            alt={t('liveblog.sport.player.img.alt', {name: eventPlayer.player?.name})} />
        </div>
      }

      <div className="tik4-plcard__m">
        <div className="tik4-plcard__n">
          {eventPlayer.player?.name}
        </div>

        { eventPlayer.player?.about &&
          <div className="tik4-plcard__a">
            {eventPlayer.player.about}
          </div>
        }

        {/* TODO:Timo output team name one day (maybe)? */}
        {/* if has details */}
        { (eventPlayer.player?.team_member_since || eventPlayer.player?.country || eventPlayer.player?.birthdate || eventPlayer.player?.weight || eventPlayer.player?.height || eventPlayer.player?.position || eventPlayer.player?.number) &&
          <div className="tik4-pld tik4-plcard__pld">
            <SportEventContentViewPlayerItem label={t('liveblog.sport.player.card.position.label')} value={eventPlayer.player?.position ? t(`liveblog.sport.player.card.position.${eventPlayer.player.position}`) : undefined} />
            <SportEventContentViewPlayerItem label={t('liveblog.sport.player.card.number.label')} value={eventPlayer.player?.number} />
            <SportEventContentViewPlayerItem label={t('liveblog.sport.player.card.team_member_since.label')} value={eventPlayer.player?.team_member_since ? <NiceDateTime onlyDate={true} skipRelative={true} ts={eventPlayer.player.team_member_since} /> : undefined } />
            <SportEventContentViewPlayerItem label={t('liveblog.sport.player.card.country.label')} value={eventPlayer.player?.country && new Intl.DisplayNames([i18n.language], { type: "region" }).of(eventPlayer.player?.country)} />
            <SportEventContentViewPlayerItem label={t('liveblog.sport.player.card.birthdate.label')} value={eventPlayer.player?.birthdate ? <NiceDateTime onlyDate={true} skipRelative={true} ts={eventPlayer.player?.birthdate}/> : undefined} />
            <SportEventContentViewPlayerItem label={t('liveblog.sport.player.card.weight.label')} value={eventPlayer.player?.weight ? `${eventPlayer.player?.weight/1000} kg` : undefined} />
            <SportEventContentViewPlayerItem label={t('liveblog.sport.player.card.height.label')} value={eventPlayer.player?.height ? `${eventPlayer.player?.height} cm` : undefined} />
          </div>
        }

        { (eventPlayer.player?.facebook || eventPlayer.player?.instagram || eventPlayer.player?.twitter) &&
          <div className="tik4-plcard__sm">
            {eventPlayer.player?.instagram &&
              <a className="tik4-link tik4-plcard__sm__l tik4-plcard__sm__l--instagram" href={eventPlayer.player.instagram} target="_blank" rel="noreferrer"><Instagram className="tik4-icon tik4-plcard__sm__i" /><span className="tik4-plcard__sm__t">Instagram</span></a>
            }
            {eventPlayer.player?.twitter &&
              <a className="tik4-link tik4-plcard__sm__l tik4-plcard__sm__l--twitter" href={eventPlayer.player.twitter} target="_blank" rel="noreferrer"><XSocial className="tik4-icon tik4-plcard__sm__i" /><span className="tik4-plcard__sm__t">Twitter</span></a>
            }
            {eventPlayer.player?.facebook &&
              <a className="tik4-link tik4-plcard__sm__l tik4-plcard__sm__l--instagram" href={eventPlayer.player.facebook} target="_blank" rel="noreferrer"><Facebook className="tik4-icon tik4-plcard__sm__i" /><span className="tik4-plcard__sm__t">Facebook</span></a>
            }
          </div>
        }

      </div>

    </div>
  </React.Fragment>

  }
