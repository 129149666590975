export { isApiResponseError } from "@webng/tickaroo-api";

export function isDOMError(error: Error): boolean {
  return error instanceof DOMException || error instanceof RangeError;
}

export function isChunkLoadError(error: Error): boolean {
  return (
    error.message.includes("ChunkLoadError") ||
    error.name.includes("ChunkLoadError")
  );
}

export function isClientIntegrationError(error: Error): boolean {
  return error.message.includes("ClientIntegrationError");
}

export function isWidgetNotInitialized(error: Error): boolean {
  return error.message === "Widget not initialized";
}

export function isFailedToFetch(error: Error): boolean {
  return error.message === "Failed to fetch";
}

