import {
  IAbstractMediaContent,
  IEvent,
  IImageMediaContent,
  IMultiMediaContentBlock,
  IVideoMediaContent
} from "@webng-types/write-model";
import {ImageMediaItemViewModel, MediaItemViewModel, VideoMediaItemViewModel} from "./ViewModel";
import {aspectWidthSizedImageUrl, ReturnImage, thumbnailSource} from "./imageUrl";


function liveblogImageMediaItemConverter(event: IEvent,
                                         contentblock: IMultiMediaContentBlock,
                                         item: IImageMediaContent): ImageMediaItemViewModel {
  const media = event.media?.find(media => media.local_id === item.media_local_id);

  const tSource = thumbnailSource(media)

  const sourceWidth = tSource.width || 960;
  let previews = new Array<ReturnImage>();

  previews.push(aspectWidthSizedImageUrl(tSource, 320));

  if (sourceWidth >= 420) previews.push(aspectWidthSizedImageUrl(tSource, 420, {format: 'webp'}));
  if (sourceWidth >= 640) previews.push(aspectWidthSizedImageUrl(tSource, 640, {format: 'webp'}));
  if (sourceWidth >= 960) previews.push(aspectWidthSizedImageUrl(tSource, 960, {format: 'webp'}));
  if (sourceWidth >= 1250) previews.push(aspectWidthSizedImageUrl(tSource, 1260, {format: "webp"}));
  if (sourceWidth >= 1600) previews.push(aspectWidthSizedImageUrl(tSource, 1600, {format: "webp"}));
  // if(sourceWidth > 1600) previews.push(aspectWidthSizedImageUrl(tSource, sourceWidth)); // don't use sizes this big, it will just produce a ton of traffic

  return {
    kind: "image",
    id: item.media_local_id!,
    title: item.title || "",
    credit: item.credit || "",
    contentWarning: item.content_warning,
    url: media?.url || "",
    originalWidth: tSource.width || 0,
    originalHeight: tSource.height || 0,
    previews: previews,
    media: media
  };
}

function liveblogVideoMediaItemConverter(event: IEvent,
                                         contentBlock: IMultiMediaContentBlock,
                                         item: IVideoMediaContent): VideoMediaItemViewModel {
  const media = event.media?.find(media => media.local_id === item.media_local_id);

  const tSource = thumbnailSource(media)

  return {
    kind: "video",
    id: item.media_local_id!,
    title: item.title || "",
    credit: item.credit || "",
    contentWarning: item.content_warning,
    originalWidth: tSource.width || 0,
    originalHeight: tSource.height || 0,
    duration: media?.duration_ms || 0,
    previews: [
      aspectWidthSizedImageUrl(tSource, 160, {format: 'webp'}),
      aspectWidthSizedImageUrl(tSource, 320, {format: 'webp'}),
      aspectWidthSizedImageUrl(tSource, 640, {format: 'webp'}),
      aspectWidthSizedImageUrl(tSource, 960, {format: 'webp'})
    ],
    media: media
  };
}

export function liveblogMediaItemConverter(event: IEvent,
                                           contentBlock: IMultiMediaContentBlock,
                                           item: IAbstractMediaContent): MediaItemViewModel {
  switch(item._type) {
    case "Tik::Model::Content::ImageMediaContent":
      return liveblogImageMediaItemConverter(event, contentBlock, item);
    case "Tik::Model::Content::VideoMediaContent":
      return liveblogVideoMediaItemConverter(event, contentBlock, item);
    default:
      throw new Error("Unknown content type: " + item._type)
  }
}
