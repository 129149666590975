import {DprApiImage} from "../ApiImage";
import React from "react";
import {IEditor} from "@webng-types/write-model/index";

export interface EventAuthorProps {
  className?: string
  editor: IEditor
  showByline?: boolean
}

export function EventAuthor({className, editor, showByline}: EventAuthorProps) {

  return <div className={className || 'tik4-author'}>
    <div className="tik4-author__wrapper">
      <div className="tik4-author__name" title={editor.name}>
        <div className="tik4-author__name__t">
          {editor.name}
        </div>
        {editor.local_byline && showByline &&
          <div className="tik4-author__name__bl" title={editor.local_byline}>
            {editor.local_byline}
          </div>
        }
      </div>
      { editor.image &&
        <div className="tik4-author__thumb">
          <DprApiImage
              className="tik4-author__thumb__img"
              alt={editor.name}
              image={editor.image}
              width={64}
              height={64}
              fit="cover"
              />
        </div>
      }
    </div>
  </div>;
}

