export const providerConstraintValues = ['full', 'simple', 'consent', 'hide', 'disabled'] as const

export type ProviderConstraintValue = typeof providerConstraintValues[number]
export type ProviderConstraints = Record<string, ProviderConstraintValue | undefined>


export type ConsentListener = (provider: string, consent: boolean) => void

export interface ConsentProvider {
  getConsent: (provider: string) => boolean
  setConsent: (provider: string, consent: boolean) => void
  requestConsent: (provider: string, consent: boolean) => void
  addConsentListener: (listener: ConsentListener) => void
  removeConsentListener: (listener: ConsentListener) => void
}
