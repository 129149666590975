import {ICountingGameOptions, IEvent, IGame, ITimedGameOptions, IUserModification} from "@webng-types/write-model";
import React from "react";

export type SportDisplayTimeFormat = 'MM:ss' | 'M:ss' | 'M\'' | 'M.' | 'M' | ''
export type SportDisplayPhaseFormat = 'long' | 'short' | ''
export type SportDisplayPhaseOvertimeFormat = 'overtime' | 'extra-time'

//TODO can we use embedjs types? failed because of circular dependency
const automaticSummaryTypes = ["chapters", "game-summary", "highlights", "highlights-slider", "none"] as const
type AutomaticSummaryType = typeof automaticSummaryTypes[number]

export interface SportstypeSpec {
  id: string
  scoreboardType?: ScoreboardType
  editorPreviewAutomaticSummary: AutomaticSummaryType,
  svg: React.VFC<React.SVGProps<SVGSVGElement>>,
  releaseStage: SportReleaseStage,
  defaultTiming?: ITimedGameOptions | ICountingGameOptions
  displayTimeFormat?: SportDisplayTimeFormat
  displayPhaseFormat?: SportDisplayPhaseFormat
  displayPhaseOvertimeFormat?: SportDisplayPhaseOvertimeFormat
  events: SportstypeEventSpec[],
  legacyEventMap?: Record<number, string|undefined>,
  isFastSportstype?: boolean
}

export type ScoreboardType = 'team-game-scoreboard' | 'set-based-scoreboard' | 'baseball-scoreboard' | 'gaelic-scoreboard'
export type SportEventTeamSelectSpec = 'any'|'none'|'home'|'guest'
export type SportEventPlayerSelectSpec = 'selected-team'|'other-team'|'any'|'none'|'home'|'guest'
export type SportEventPlayerId = "scorer"|"assist"|"shooter"|"keeper"|"injured"|"offender"|"in"|"out"|"rider"
export type SportReleaseStage = 'alpha' | 'beta' | 'released'

export enum TeamGameScoreboardDisplayTypes {
  DEFAULT = "default",
  DEFAULT_LOGO = "default_logo",
  DEFAULT_TEXT = "default_text",
  TABLE = "table",
  TABLE_TEXT = "table_text"
}

export enum SetBasedScoreboardDisplayTypes {
  DEFAULT = "default",
  DEFAULT_ONLY_SCORES = "default_only_scores",
  THUMB = "thumb",
  THUMB_ONLY_SCORES = "thumb_only_scores"
}

export interface SportEventPlayerSpec {
  id: SportEventPlayerId
  from?: SportEventPlayerSelectSpec
  max?: number
}

export interface SportstypeEventSpec {
  id: string
  important: boolean
  svg: React.VFC<React.SVGProps<SVGSVGElement>>
  team?: SportEventTeamSelectSpec
  players?: SportEventPlayerSpec[]
  scoreChange?: number
  scoreChangeOpponent?: number,
  eventHandler?: (game: IGame, event: IEvent, isNewEvent: boolean) => IUserModification[],
}

export interface SportPhaseTimingSpec {

}

export interface SportPhaseSpec {
  id: string
  repeats?: number
  hasBreaks?: boolean
}

export interface SportMetadata {
  sportstypes: SportstypeSpec[]
  phases: SportPhaseSpec[]
}
