import {IEditor, IMedia, IOwner} from "@webng-types/write-model";

// This is hacky in here

interface IMediaWithSync extends IMedia {
  syncMedia: any
}

function isWithSyncMedia(media: IMedia|IMediaWithSync|undefined): media is IMediaWithSync {
  // noinspection RedundantIfStatementJS
  if(media && (media as IMediaWithSync).syncMedia !== undefined) {
    return true
  } else {
    return false;
  }
}


interface SourceImage {
  url: string
  width?: number
  height?: number
}

interface TargetImage {
  width: number
  height: number
  fit?: 'cover'|'contain'
  format?: 'webp'|'jpeg'|'png'
}

export interface ReturnImage {
  url: string
  width: number
  height: number
}

function placeholderSource(subtype?: string): SourceImage {
  if(subtype === 'v') {
    return {
      url: "iconpack://pending_video.svg",
      width: 96,
      height: 96
    }
  } else {
    return {
      url: "iconpack://pending_image.svg",
      width: 96,
      height: 96
    }
  }
}

export function thumbnailSource(media?: IMedia): SourceImage {
  if(media) {
    if (media.subtype === 'v') {
      return videoThumbnailSource(media);
    } else {
      return mediaThumbnailSource(media);
    }
  } else {
    return placeholderSource()
  }
}

export function mediaThumbnailSource(media: IMedia): SourceImage {
  if(media.url) {
    return {
      url: media.url,
      width: media.width,
      height: media.height
    }
  } else if(isWithSyncMedia(media) && media.syncMedia?.previewStatus === "success") {
    return {
      url: media.syncMedia.thumbnailUrl!,
      width: media.syncMedia.width,
      height: media.syncMedia.height
    }
  } else {
    return placeholderSource("i")
  }
}


export function ownerThumbnailSource(owner: IOwner): SourceImage|undefined {
  if(owner.image) {
    return {
      url: owner.image
    }
  } else {
    return undefined
  }
}

export function editorThumbnail(editor: IEditor): ReturnImage|undefined {
  if(editor.image) {
    return exactSizeImageUrl(editor.image, {width: 100, height: 100})
  } else {
    return undefined
  }
}

export function videoThumbnailSource(media: IMedia): SourceImage {
  if(!media.needs_upload_from_device && media.url) {
    if(media.url.startsWith("imageservice://media/")) { // aws mediaconvert style
      return {
        url: media.url + ".raw.0000000.jpg",
        width: media.width,
        height: media.height
      }
    } else { // zencoder style
      return {
        url: media.url + "/frame_0002.jpg",
        width: media.width,
        height: media.height
      }
    }
  } else if(isWithSyncMedia(media) && media.syncMedia?.previewStatus === "success") {
    return {
      url: media.syncMedia.thumbnailUrl!,
      width: media.syncMedia.width,
      height: media.syncMedia.height
    }
  } else {
    return placeholderSource("v")
  }
}


export function aspectWidthSizedImageUrl(source: SourceImage, targetWidth: number, options: Omit<TargetImage, "width"|"height"> = {}): ReturnImage {
  if(source.width && source.height) {
    const targetHeight = Math.floor((targetWidth/source.width) * source.height);
    return exactSizeImageUrl(source.url, {width: targetWidth, height: targetHeight, ...options});
  } else {
    // noinspection JSSuspiciousNameCombination
    return exactSizeImageUrl(source.url, {width: targetWidth, height: targetWidth, ...options});
  }
}

// export function aspectHeightSizedImageUrl(source: SourceImage, targetHeight: number): ReturnImage {
//   if(source.width && source.height) {
//     const targetWidth = Math.floor((targetHeight/source.height) * source.width);
//     return exactSizeImageUrl(source.url, {width: targetWidth, height: targetHeight});
//   } else {
//     // noinspection JSSuspiciousNameCombination
//     return exactSizeImageUrl(source.url, {width: targetHeight, height: targetHeight});
//   }
// }
//
export function aspectFitSizedImageUrl(source: SourceImage, target: TargetSize, options: Omit<TargetImage, "width"|"height"> = {}): ReturnImage {
  const size = aspectFit(source, target)
  return exactSizeImageUrl(source.url, {...size, ...options})
}


export function exactSizeImageUrl(url: string, target: TargetImage): ReturnImage {
  let parsedUrl: URL;

  try {
    parsedUrl = new URL(url);
  } catch(e) {
    parsedUrl = new URL("tik-iconpack://webembed_failed.svg")
  }

  if (parsedUrl.protocol === 'blob:' || parsedUrl.protocol === "data:") {
    return {
      ...target,
      url
    };
  } else if(parsedUrl.protocol === 'iconpack:' || parsedUrl.protocol === 'tik-iconpack:') {
    // there are some browser differences in parsing (pathname = chrome, hostname = safari)
    const icon = parsedUrl.pathname ? parsedUrl.pathname.substr(2) : parsedUrl.hostname
    return {
      ...target,
      url: "/iconpack/" + icon
    }
  } else {
    const filter = target.fit === 'contain' ? 'ThumbnailatorAspectFitFilter' : 'ThumbnailatorCropResizeCenterFillFilter'

    const options = [
      "w" + target.width + "dp",
      "h" + target.height + "dp",
    ]

    if(target.format) {
      options.push(`${target.format}`)
    }

    return {
      ...target,
      url: `/api/mediaproxy/v1/external-image/${filter}/${options.join('-')}?url=${encodeURIComponent(url)}`,
    }
  }
}

export interface VideoFrameOptions {
  access_token?: string
  notrack?: "true"
  autoplay?: "true"
  client_id?: string
}

export function videoFrameUrl(gameId: string, eventId: string, mediaId: string, opts: VideoFrameOptions): string {
  // https://staging.tickaroo.com/embed/video-frame/00kdsz5t6c4cr2uq0qxw2w.html?access_token=bce290df55d35017e4b0a70eb47e245fXzVgB5YsPPothAPHFdPtiCPCgPXK-tSlyF9svcNdtBJKpFAf&autoplay=true&autoplay=true
  const query = new URLSearchParams(opts as Record<string,string>).toString()
  return `/embed/v4/video-frame/${encodeURIComponent(gameId)}/${encodeURIComponent(eventId)}/${encodeURIComponent(mediaId)}.html?${query}`
}

export enum VideoVariant {
  SD,
  HD
}

interface SourceSize {width?: number, height?: number}
export interface TargetSize {width: number, height: number}

export function aspectFit(tSource: SourceSize, target: TargetSize): TargetSize {
  if(tSource.width && tSource.height) {
    if (tSource.width/tSource.height < target.width/target.height) {
      return {
        width: Math.floor((target.height/tSource.height) * tSource.width),
        height: target.height
      }
    } else {
      return {
        width: target.width,
        height: Math.floor((target.width/tSource.width) * tSource.height)
      }
    }
  } else {
    return target;
  }
}


// v2/mobile/advanced: {"size": "1280x720", "audio_bitrate": 56, "max_audio_sample_rate": 48000, "max_frame_rate": 30, "max_video_bitrate": 5000, "video_codec_level": 3.1, "videoe_codec_profile": "main"}
// v2/mobile/baseline: {"size": "480x320", "audio_bitrate": 96, "max_audio_sample_rate": 44100, "max_frame_rate": 30, "max_video_bitrate": 1500, "video_reference_frames": 1}
// v2/mobile/legacy: {"size": "320x240", "audio_bitrate": 96, "max_audio_sample_rate": 44100, "max_frame_rate": 30, "max_video_bitrate": 768, "video_codec_level": 1.3, "video_reference_frames": 1}

export function videoVariant(media: IMedia, tSource: SourceSize, variant: VideoVariant) {
  if(media.url?.startsWith("imageservice://media/")) { // new style aws target
    switch (variant) {
      case VideoVariant.SD:
        return {
          ...aspectFit(tSource, {width: 480, height: 320}),
          url: media.url?.replace('imageservice:/', '') + ".sd.mp4"
        }
      case VideoVariant.HD:
        return {
          ...aspectFit(tSource, {width: 1280, height: 720}),
          url: media.url?.replace('imageservice:/', '') + ".hd.mp4"
        }
    }
  } else if(media.url?.startsWith("imageservice")) { // old style zencoder target
    const encodedId = 'media-' + encodeURIComponent(media.local_id!);

    switch (variant) {
      case VideoVariant.SD:
        return {
          ...aspectFit(tSource, {width: 480, height: 320}),
          url: "https://mediaproxy.tickaroo.com/" + encodedId + "/mobile_baseline.mp4"
        }
      case VideoVariant.HD:
        return {
          ...aspectFit(tSource, {width: 1280, height: 720}),
          url: "https://mediaproxy.tickaroo.com/" + encodedId + "/mobile_advanced.mp4"
        }
    }
  } else if(media.url?.startsWith("http")) { // external hosted
    return {
      ...tSource,
      url: media.url
    }
  } else {
    return {
      ...tSource,
      url: undefined
    }
  }
}
