import loadable from "@loadable/component";
import { CircleLoader } from "@webng/icons-common";
import React from "react";
import { PollContentBlockProps } from "../content/PollContentBlockView";
import { ErrorBoundary } from "@webng/react-app-common";

const PollContentBlockWrapper = loadable(() => import('./QueryClientWrapper').then(wrapper => wrapper.PollContentBlockWrapper), {
	fallback: <div className="tik4-content-block tik4-content-block--comments">
    <div className="tik4-comments">
      <div className="tik4-comments__loader">
        {/* {t('liveblog.comments.preview.loading')} */}
        <CircleLoader className="tik4-icon tik4-spin"/>
      </div>
    </div>
</div>
})

export function PollContentBlock({block, isPreview, event}: PollContentBlockProps) {

	return <ErrorBoundary>
		<PollContentBlockWrapper block={block} isPreview={isPreview} event={event} />
	</ErrorBoundary>
}