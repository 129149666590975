import React, {useCallback} from "react";
import {EmbedProviderProps} from "./EmbedProviders";
import {ensureScriptTag} from "./tagManager";

export function Instagram({oembed, locale}: EmbedProviderProps) {

  const ref = useCallback((element: HTMLDivElement|null) => {
    if(element) {
      if((window as any).instgrm) {
        (window as any).instgrm.Embeds.process();
      } else {
        ensureScriptTag({src: "https://www.instagram.com/embed.js"})
      }
    }
  }, [])

  return <div className="tik4-we-instagram" ref={ref} dangerouslySetInnerHTML={{__html: oembed.html || ""}} />
}

export default Instagram


