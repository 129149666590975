function storageCascade(s: Storage) {
  return {
    get: function(key: string) {
      try {
        if(s) {
          return s.getItem(key);
        } else {
          return null;
        }
      } catch(e) {
        return null;
      }
    },
    set: function(key: string, value: string) {
      try {
        if(s) {
          s.setItem(key, value);
          return true;
        } else {
          return false;
        }
      } catch(e) {
        return false;
      }
    },
    remove: function(key: string) {
      try {
        s.removeItem(key)
      } catch(e) {
        // empty
      }
    }
  };
}

var cascade = [
  storageCascade(window.localStorage),
  storageCascade(window.sessionStorage)
];


export function get(key: string) {
  for(let i = 0; i < cascade.length; ++i) {
    var val = cascade[i].get(key);
    if(val) {
      return val;
    }
  }
  return null;
}

export function set(key: string, value: string) {
  let hasSet = false;
  for(let i = 0; i < cascade.length; ++i) {
    if(cascade[i].set(key, value)) {
      hasSet = true;
    }
  }
  return hasSet;
}

export function remove(key: string) {
  for(let i = 0; i < cascade.length; ++i) {
    cascade[i].remove(key)
  }
}
