import {LiveblogWidgetInitialData, SortOrder} from "@webng-types/embedjs";
import {useApi} from "@webng/react-app-common";
import {ApiClient, isApiResponseError} from "@webng/tickaroo-api";
import {useEffect, useState} from "react";

interface UseLiveblogWidgetDataProps {
  clientId: string
  themeId: string
  liveblogId: string
  limit?: number
  sort?: SortOrder
  initialData?: LiveblogWidgetInitialData
}

interface Query {
  client_id: string
  themeId: string
  liveblogId: string
  limit?: number
  sort?: SortOrder
}

export function useLiveblogWidgetData({clientId, themeId, liveblogId, limit, sort, initialData}: UseLiveblogWidgetDataProps) {
  const apiClient = useApi()
  const [data, setData] = useState<LiveblogWidgetInitialData>(initialData || {})
  const [error, setError] = useState<number|undefined>(undefined)

  useEffect(() => {
    if(!data.configuration) {
      const query: Query = {client_id: clientId, themeId, liveblogId}
      if(limit){
        query["limit"] = limit;
      }
      if(sort){
        query["sort"] = sort;
      }

      apiClient.getJson(ApiClient.API_EMBED_V4_LIVEBLOG, query)
        .then(setData)
        .catch(e => {
          if(isApiResponseError(e)) {
            setError(e.status)
          } else {
            setError(0)
          }
        })
    }
  }, [apiClient, clientId, themeId, liveblogId, data, setData, setError, limit, sort])

  return {
    ...data,
    error
  }
}
