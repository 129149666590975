import React, {useState} from 'react';

const URL = "https://dataless.dpa-prototype.de/pixel.gif";

export default function DpaTracking(){
  const [src, setSrc] = useState("data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH+EUNyZWF0ZWQgd2l0aCBHSU1QACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==")

  function handleLoad(){
    if (((Date.now() % 100)===0)) {
      setSrc(URL + '?_d=100&p=tckr&r=' + encodeURIComponent(document.referrer) + '&l=' + encodeURIComponent(document.location.toString()))
    }
  }

  return <img style={{width: "1px", height: "1px", visibility:"hidden"}}
              onLoad={handleLoad}
              src={src} alt="" />
}
