import React, {useEffect, useRef} from "react";
import {EmbedProviderProps} from "./EmbedProviders";

export function ExecutableProvider({oembed}: EmbedProviderProps) {
  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!oembed.html) return

    try {
      const slotHtml = document.createRange().createContextualFragment(oembed.html) // Create a 'tiny' document and parse the html string
      if (divRef.current !== null) {
        divRef.current.innerHTML = '' // Clear the container
        divRef.current.appendChild(slotHtml) // Append the new content
      }
    } catch (e) {
      console.error(e)
    }
  }, [oembed.html])


  return <div className={`tik4-we-executable tik4-we-executable--${oembed.provider_name?.toLowerCase()}`}>
    <div className="tik4-we-executable__container" ref={divRef}/>
  </div>
}

export default ExecutableProvider

