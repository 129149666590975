import React, {
  ForwardedRef,
  forwardRef,
  RefObject,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from "react"
import {EnvironmentProvider, ErrorBoundary, useEnvironment} from "@webng/react-app-common";

import globalSettings from "../globalSettings";
import {fireTickarooLiveblogInitEvent, fireTickarooLiveblogTracking} from "../events";
import {
  DefaultConsentProvider,
  Liveblog,
  LiveblogEvent,
  LiveblogRenderContext,
  LiveblogRenderContextProvider,
  LiveblogWrapper,
  TrackingFunction
} from "@webng/liveblog";
import {I18nextProvider} from "react-i18next";
import i18n, {detectHtmlLanguage} from '../i18n'
import {LiveblogCoreApi, LiveblogCoreWidgetProps, NavigateToEventIdOptions,} from "./LiveblogCoreWidgetProps";
import * as externalUserProvider from '../user'
import {useLiveblogAnalyticsAgent} from "../analytics";
import {useLiveblogData} from "./useLiveblogData";
import {IEvent, IMedia, ITag} from "@webng-types/write-model";
import {LoadErrorView} from "../LoadErrorView";
import DpaTracking from "./DpaTracking";
import {CustomConsentProvider} from "./CustomConsentProvider";
import {useAdExtension} from "../../ads/useAdExtension";
import {AdManager} from "../../ads/AdManager";


type LiveblogCoreControllerProps = Omit<LiveblogCoreWidgetProps, "locale" | "clientId">

const DPA_PRODUCTION_ORGA_ID = '58514026e4b08f124d8e26e6';
const DPA_STAGING_ORGA_ID = '583303a3e4b0085862630479';

function apiCanNotLoadMore(): Promise<void> {
  return Promise.reject("No more data");
}

function computeFixedHeaderOffset() {
  if(document.elementsFromPoint) {
    const fixedHeader = document.elementsFromPoint(0, 0)
      .filter(e => {
        const position = window.getComputedStyle(e).position;
        return position === 'fixed' || position === 'sticky'
      })
      .map(e => e.getBoundingClientRect().height)
      .filter(h => h > 0 && h < 250) //sanity check
      .reduce((a, b) => Math.max(a, b), 0);

    if (fixedHeader !== 0) {
      return fixedHeader
    } else {
      return undefined
    }
  } else {
    return undefined
  }
}

function scrollToElement(el: Element, options?: { topOffset?: number }) {
  // el.scrollIntoView({behavior: 'smooth', block: 'center'})
  if (el) {
    var eventClientRect = el.getBoundingClientRect();
    var top = window.scrollY + eventClientRect.top - (options?.topOffset || computeFixedHeaderOffset() || 0);
    if(window && typeof window.tickarooLiveblogScrollTo === 'function') {
      window.tickarooLiveblogScrollTo(top)
    } else {
      window.scrollTo({
        behavior: "smooth",
        top: top
      });
    }
  }
}

function LiveblogCoreControllerImp({ liveblogId, customerConsent, refresh, sort, colorScheme, layout, layoutAuthorPosition, layoutEventTagPosition, styleUseCards, stylePrimaryColor, styleSecondaryColor, stylePrimaryColorDark, styleSecondaryColorDark, styleTextColor, styleTextColorDark, styleTextMutedColor, styleTextMutedColorDark, styleBgColor, styleBgColorDark, styleEventHighlightBgColor, styleEventHighlightBgColorDark, styleEventStickyBgColor, styleEventStickyBgColorDark, css, limit, deepLinkLimit, deepLinkDepth, eventId, webEmbedDefaultConstraint, webEmbedConstraints, sharing, styleInvertHighlightEvents, styleInvertStickyEvents, styleInvertTime, useSlideshow, automaticSummary, automaticSummaryHighlightsLimit, commentsMode, useCookies, initialData, disableTracking, eventTagNavigation }: LiveblogCoreControllerProps, ref: ForwardedRef<LiveblogCoreApi>) {
  const agent = useLiveblogAnalyticsAgent(liveblogId, useCookies === 'all', disableTracking);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const env = useEnvironment();
  const [adManager, setAdManager] = useState<AdManager>()
  const [headerOffset, setHeaderOffset] = useState<number|undefined>(undefined)

  const trackingFunction: TrackingFunction = useCallback((e: LiveblogEvent) => {
    if(agent) {
      agent.track(e)
      if (wrapperRef.current && wrapperRef.current.parentElement) {
        fireTickarooLiveblogTracking(wrapperRef.current.parentElement, e)
      }
    }
  }, [agent])

  const renderContext: Partial<LiveblogRenderContext> = useMemo(() => {
    const useFunctionalCookies = useCookies === 'all' || useCookies === 'functional'
    return {
      consentProvider: customerConsent ? new CustomConsentProvider(useFunctionalCookies, wrapperRef as RefObject<HTMLDivElement>) : new DefaultConsentProvider(useFunctionalCookies),
      webEmbedDefaultProviderConstraint: webEmbedDefaultConstraint,
      webEmbedProviderConstraints: Object.assign({}, webEmbedConstraints, { Website: 'full' }),
      showEventSharing: sharing,
      layoutAuthorPosition: layoutAuthorPosition,
      commentsMode: commentsMode,
      externalUserProvider: externalUserProvider,
      uniqueId: initialData.uniqueId,
      useFunctionalCookies,
      trackingFunction
    }
  }, [webEmbedDefaultConstraint, webEmbedConstraints, commentsMode, useCookies, sharing, layoutAuthorPosition, initialData.uniqueId, customerConsent, trackingFunction]);

  const {
    error,
    game,
    rows,
    pendingUpdate,
    milestones,
    goals,
    highlights,
    loadMoreBottom,
    loadMoreTop,
    backToTop,
    navigateToEventId,
    selectedTag,
    setSelectedTag,
    applyPendingUpdate,
    isLoadingBackToLive,
    isLoadingMoreTop,
    isLoadingMoreBottom,
    isLoadingPendingUpdate
  } = useLiveblogData({
    id: liveblogId,
    limit,
    deepLinkLimit,
    deepLinkDepth,
    trackingFunction,
    refresh,
    reverse: sort === 'asc',
    initialData: initialData.gameShowResponse,
    refreshImmediately: initialData.isSSR
  })

  const navigateToEventIdWrap = useCallback((eventId: string | undefined, options?: NavigateToEventIdOptions) => {
    if (eventId) {
      const existingElement = wrapperRef.current?.querySelector(`[data-tickaroo-event-id="${eventId}"]`)
      if (existingElement) {
        scrollToElement(existingElement, options)
        return Promise.resolve(true)
      } else {
        return navigateToEventId(eventId).then(_ => {
          const existingElement = wrapperRef.current?.querySelector(`[data-tickaroo-event-id="${eventId}"]`)
          if (existingElement) {
            scrollToElement(existingElement, options)
            return true;
          } else {
            return false;
          }
        });
      }
    } else {
      return navigateToEventId(eventId).then(_ => true);
    }
  }, [navigateToEventId])

  // provide imperative methods on the liveblog element
  useImperativeHandle<LiveblogCoreApi, LiveblogCoreApi>(ref, () => ({
    loadMoreTop: loadMoreTop || apiCanNotLoadMore,
    loadMoreBottom: loadMoreBottom || apiCanNotLoadMore,
    navigateToEventId: navigateToEventIdWrap,
    hasEventIdLoaded: function (eventId: string) {
      return !!rows.find(r => r.local_id === eventId)
    },
    setConsent: (provider: string, consent: boolean): void => {
      renderContext.consentProvider?.setConsent(provider, consent)
    },
    setAdManager
  }))

  // dispatch init event
  useEffect(() => {
    if (wrapperRef.current && wrapperRef.current.parentElement) {
      fireTickarooLiveblogInitEvent(wrapperRef.current.parentElement, { liveblogId: liveblogId })
    }
  }, [liveblogId])

  // dispatch tracking init event
  useEffect(() => {
    trackingFunction({ t: 't_ini' })
  }, [trackingFunction])

  // react to eventId
  useEffect(() => {
    if (eventId) {
      navigateToEventIdWrap(eventId)
    }
    // navigateToEventIdWrap is not in the dependencies list as it would cause
    // this callback to be called to often. we only want to call it for the initial eventId jump
    // eslint-disable-next-line
  }, [eventId])

  const onMilestoneClick = useCallback((milestone: IEvent) => {
    trackingFunction({ t: 't_mil', m: milestone.local_id })

    navigateToEventIdWrap(milestone.local_id)
  }, [navigateToEventIdWrap, trackingFunction])

  const onHighlightClick = useCallback((highlight: IEvent) => {
    trackingFunction({ t: 't_hlt', m: highlight.local_id })

    navigateToEventIdWrap(highlight.local_id)
  }, [navigateToEventIdWrap, trackingFunction])

  const onTagClick = useCallback((tag: ITag|undefined) => {
    trackingFunction({ t: 't_tag' })
    if (tag === undefined || tag._id === selectedTag) {
      setSelectedTag(undefined)
    } else {
      setSelectedTag(tag._id)
    }
  }, [setSelectedTag, selectedTag, trackingFunction])

  const onMediaClick = useCallback((media: IMedia) => {
    trackingFunction({ t: 's_opn', m: media.local_id })
    import('../../slideshow/slideshow').then(m => m.startSlideshow(rows, media.local_id, trackingFunction, env.apiHost))
  }, [rows, trackingFunction, env.apiHost])


  const onApplyPendingUpdates = useCallback(() => {
    applyPendingUpdate();
    setTimeout(() => wrapperRef.current && scrollToElement(wrapperRef.current), 10);
  }, [applyPendingUpdate, wrapperRef])

  const hasPendingUpdate = !!pendingUpdate
  useEffect(() => {
    if(hasPendingUpdate) {
      setHeaderOffset(computeFixedHeaderOffset());

      const interval = setInterval(() => {
        setHeaderOffset(computeFixedHeaderOffset())
      }, 500)

      return () => clearInterval(interval)
    }
  }, [hasPendingUpdate]);

  const backToLiveLink = backToTop ? "#" : undefined
  const loadMoreTopLink = loadMoreTop ? "#" : undefined
  const loadMoreBottomLink = loadMoreBottom ? "#" : undefined

  const includeDpaTracking = !!game.upstreams?.find(u => u._type === "Tik::Model::Upstream::MarketplaceUpstream" && u.seller && (u.seller._id === DPA_PRODUCTION_ORGA_ID || u.seller._id === DPA_STAGING_ORGA_ID));

  const extension = useAdExtension(adManager, rows)


  if (error !== undefined) {
    return <LoadErrorView status={error} />
  } else {
    return <>
      <LiveblogRenderContextProvider context={renderContext}>
        <LiveblogWrapper
          ref={wrapperRef}
          liveblogId={liveblogId}
          sharing={sharing}
          invertHighlightEvents={styleInvertHighlightEvents}
          invertStickyEvents={styleInvertStickyEvents}
          invertTime={styleInvertTime}
          colorScheme={colorScheme}
          layout={layout}
          layoutAuthorPosition={layoutAuthorPosition}
          layoutEventTagPosition={layoutEventTagPosition}
          styleUseCards={styleUseCards}
          stylePrimaryColor={stylePrimaryColor}
          stylePrimaryColorDark={stylePrimaryColorDark}
          styleSecondaryColor={styleSecondaryColor}
          styleSecondaryColorDark={styleSecondaryColorDark}
          styleTextColor={styleTextColor}
          styleTextColorDark={styleTextColorDark}
          styleTextMutedColor={styleTextMutedColor}
          styleTextMutedColorDark={styleTextMutedColorDark}
          styleBgColor={styleBgColor}
          styleBgColorDark={styleBgColorDark}
          styleEventHighlightBgColor={styleEventHighlightBgColor}
          styleEventHighlightBgColorDark={styleEventHighlightBgColorDark}
          styleEventStickyBgColor={styleEventStickyBgColor}
          styleEventStickyBgColorDark={styleEventStickyBgColorDark}
          headerOffset={headerOffset}
          css={css}>
          <Liveblog
            game={game}
            milestones={milestones}
            goals={goals}
            highlights={highlights}
            rows={rows}
            selectedTag={selectedTag}
            pendingUpdate={pendingUpdate}
            onTagClick={onTagClick}
            onMilestoneClick={onMilestoneClick}
            onHighlightClick={onHighlightClick}
            onMediaClick={useSlideshow ? onMediaClick : undefined}
            onBackToLiveClick={backToTop}
            onLoadMoreTopClick={loadMoreTop}
            onLoadMoreBottomClick={loadMoreBottom}
            onApplyPendingUpdates={onApplyPendingUpdates}
            backToLiveLink={backToLiveLink}
            loadMoreTopLink={loadMoreTopLink}
            loadMoreBottomLink={loadMoreBottomLink}
            isLoadingBackToLive={isLoadingBackToLive}
            isLoadingMoreTop={isLoadingMoreTop}
            isLoadingMoreBottom={isLoadingMoreBottom}
            isLoadingPendingUpdate={isLoadingPendingUpdate}
            automaticSummary={automaticSummary}
            automaticSummaryHighlightsLimit={automaticSummaryHighlightsLimit}
            eventTagNavigation={eventTagNavigation}
            extension={extension}
          />
        </LiveblogWrapper>
      </LiveblogRenderContextProvider>
      {includeDpaTracking && <DpaTracking />}
    </>
  }
}

const LiveblogCoreController = forwardRef<LiveblogCoreApi, LiveblogCoreControllerProps>(LiveblogCoreControllerImp)

function LiveblogCoreWidgetImp({ clientId, locale, initialData, ...props }: LiveblogCoreWidgetProps, ref: ForwardedRef<LiveblogCoreApi>) {
  // Cloning i18n using the same language rule as the server (either set by config or english as default)
  // This ensures that hydrate finds the same html client and server
  const initLocale = locale || initialData.gameShowResponse?.game?.locale || ''
  const derivedI18n = useMemo(() => {
    return i18n.cloneInstance({
      lng: initLocale || 'en'
    })
  }, [initLocale])

  // Change the language after first rendering if auto detection is enabled
  useEffect(() => {
    if (initLocale === "") {
      derivedI18n.changeLanguage(detectHtmlLanguage())
    }
  }, [derivedI18n, initLocale])

  return <React.StrictMode>
    <ErrorBoundary>
      <EnvironmentProvider apiHost={`${globalSettings.baseUrl}`} clientId={clientId}>
        <I18nextProvider i18n={derivedI18n}>
          <LiveblogCoreController ref={ref} initialData={initialData} {...props} />
        </I18nextProvider>
      </EnvironmentProvider>
    </ErrorBoundary>
  </React.StrictMode>
}

export const LiveblogCoreWidget = forwardRef(LiveblogCoreWidgetImp)

