import {useTranslation} from "react-i18next";
import {createDateTimeFormatter} from "../i18n/locale";

const IS_MILLIS_CUTOFF = 1000000000000
const MS_PER_DAY = 86400 * 1000
const MS_PER_HOUR = 60 * 60 * 1000
const NOW_THRESHOLD = 45 * 1000
const RELATIVE_THRESHOLD = 60 * 60 * 1000

const DATETIME_FORMATTER = new Map<string, Intl.DateTimeFormat>();
const DATETIME_FORMAT: Intl.DateTimeFormatOptions= {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit"
}

const DATE_FORMATTER = new Map<string, Intl.DateTimeFormat>();
const DATE_FORMAT: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric"
}

const TIME_FORMATTER = new Map<string, Intl.DateTimeFormat>();
const TIME_FORMAT: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "2-digit"
}

const WEEKDAY_FORMATTER = new Map<string, Intl.DateTimeFormat>();
const WEEKDAY_FORMAT: Intl.DateTimeFormatOptions = {
  weekday: "long"
}

function getOrCreateFormatter(map: Map<string, Intl.DateTimeFormat>, locale: string, options: Intl.DateTimeFormatOptions): Intl.DateTimeFormat {
  const existing = map.get(locale);
  if(existing) {
    return existing;
  } else {
    const created = createDateTimeFormatter(locale, options);
    map.set(locale, created);
    return created;
  }
}

function localizeDateTime(date: Date, locale: string){
  return getOrCreateFormatter(DATETIME_FORMATTER, locale, DATETIME_FORMAT).format(date);
}

function localizeDate(date: Date, locale: string){
  return getOrCreateFormatter(DATE_FORMATTER, locale, DATE_FORMAT).format(date);
}

function localizeTime(date: Date, locale: string){
  if(locale === 'de') {
    // Hacky way to add Uhr in german language only, no i18n because other language don't have this (for now)
    return getOrCreateFormatter(TIME_FORMATTER, locale, TIME_FORMAT).format(date) + " Uhr";
  } else {
    return getOrCreateFormatter(TIME_FORMATTER, locale, TIME_FORMAT).format(date);
  }
}

function localizeWeekday(date: Date, locale: string){
  return getOrCreateFormatter(WEEKDAY_FORMATTER, locale, WEEKDAY_FORMAT).format(date);
}

function getWeekdayName(date: Date): string {
  return date.toLocaleDateString('en-US', { weekday: 'long' });
}

function beginningOfDay(ts?: number){
  const date = ts ? new Date(ts) : new Date();
  date.setHours(0,0,0,0);
  return date;
}

interface NiceDateTime{
  time?: string,
  date?: string,
  dateTime?: string,
  isoString?: string
}

export function useNiceDateTime(ts?: number|"now", skipRelative?: boolean): NiceDateTime{
  const {t, i18n} = useTranslation("liveblog");
  if(!ts){
    return {time: undefined, date: undefined};
  }

  const date = ts === 'now' ? new Date() : new Date((ts > IS_MILLIS_CUTOFF) ? ts : ts * 1000);
  const now = new Date().valueOf();
  const dateTime = localizeDateTime(date, i18n.language || "en")
  const isoString = date.toISOString()

  if(!skipRelative){
    const dist = now.valueOf() - date.valueOf();

    if (dist > -NOW_THRESHOLD && dist < NOW_THRESHOLD) {
      return {time: t('date.just_now'), dateTime: dateTime, isoString: isoString};
    } else if (dist > 0 && dist < RELATIVE_THRESHOLD) {
      const count = Math.round(dist / 60000);
      return {time: t('date.minutes_ago', {count: count}), dateTime: dateTime, isoString: isoString};
    }
  }

  // round because of clock change (first day of summer time - last day of winter time => 0.95)
  const dayDiff = Math.round((beginningOfDay().valueOf() - beginningOfDay(date.getTime()).valueOf()) / MS_PER_DAY);

  let formatedDate;

  if (dayDiff === 0) {
    formatedDate = t('date.today');
  } else if (dayDiff === 1) {
    formatedDate = t('date.yesterday');
  } else if (dayDiff === -1) {
    formatedDate = t('date.tomorrow');
  } else if (dayDiff < 4 && dayDiff > 0) {
    formatedDate = localizeWeekday(date, i18n.language);
  } else if (dayDiff > -4 && dayDiff < 0) {
    formatedDate = localizeWeekday(date, i18n.language);
  } else if (dayDiff <= -4) {
    formatedDate = localizeDate(date, i18n.language);
  } else {
    formatedDate = localizeDate(date, i18n.language);
  }

  const time = localizeTime(date, i18n.language);

  return {time: time, date: formatedDate, dateTime: dateTime, isoString: isoString};
}

export function useNiceEndTime(ts?: number|"now"): string | undefined {
  const {t, i18n} = useTranslation("liveblog")
  if(!ts) {
    return undefined
  }

  const date = ts === "now" ? new Date() : new Date((ts > IS_MILLIS_CUTOFF) ? ts : ts * 1000)
  const now = new Date().valueOf()

  const dist = date.valueOf() - now.valueOf()

  if(dist > -NOW_THRESHOLD && dist < NOW_THRESHOLD) {
    return t('date.shortly')
  } else if(dist > 0 && dist < RELATIVE_THRESHOLD) {
    const count = Math.round(dist / 60000)
    return t('date.in_minutes', {count: count})
  }

  // round because of clock change (first day of summer time - last day of winter time => 0.95)
  const dayDiff = Math.round((beginningOfDay(date.getTime()).valueOf() - beginningOfDay().valueOf()) / MS_PER_DAY);

  if(dayDiff === 0) {
    const count = Math.round(dist / MS_PER_HOUR)
    return t('date.in_hours', {count: count})
  } else if(dayDiff < 4) {
    return t('date.in_days', {count: dayDiff})
  } else if(dayDiff < 7) {
    const translation = t(`date.on_${getWeekdayName(date).toLowerCase()}`, {
      fallbackLng: undefined
    })
    if(translation && !translation.startsWith("date.on_")) {
      return translation
    }
    return t(`date.on_day_of_week`, {day: localizeWeekday(date, i18n.language)})
  } else {
    return t('date.on_date', {date: localizeDate(date, i18n.language)})
  }

}
