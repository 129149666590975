import {IEvent} from "@webng-types/write-model/index";
import React from "react";
import {DateTimeView, NiceDateTime} from "../NiceDateTime";
import {getSportTiming} from "../utils/eventUtils";
import {useNiceDateTime} from "@webng/react-app-common";

interface EventDateTimeProps {
  event: IEvent
  children?: React.ReactNode
}


export function EventDateTime({event, children}: EventDateTimeProps) {
  const sportTiming = getSportTiming(event)
  const {dateTime} = useNiceDateTime(event.created_at, true);

  return <React.Fragment>
    {sportTiming && <DateTimeView date={sportTiming.timeOfPlay ? sportTiming.phase?.name : undefined} time={sportTiming.timeOfPlay || sportTiming.phase?.name} classNamesPrefix="tik4-meta__" title={dateTime}>
      {children}
    </DateTimeView>}
    {!sportTiming && <NiceDateTime ts={event.created_at || "now"} classNamesPrefix="tik4-meta__">
      {children}
    </NiceDateTime>}
  </React.Fragment>
}
