import React from "react";
import {EventExtensionComponent} from "./Extension";
import {ErrorBoundary} from "@webng/react-app-common";
import {IEvent} from "@webng-types/write-model";

interface RenderEventExtensionProps {
  event: IEvent
  Extension?: EventExtensionComponent
}

export function EventExtensionRenderer({event, Extension}: RenderEventExtensionProps) {
  if(Extension) {
    return <ErrorBoundary>
      <Extension event={event} />
    </ErrorBoundary>
  } else {
    return null
  }
}
