import {useCallback, useEffect, useState} from 'react'

export default function useLocalStorage<T>(key: string|null, initialValue: T): [T, (value: T | ((prevState: T) => T)) => void] {
  const [storedValue, setStoredValue] = useState(() => {
    if(key != null) {
      try {
        const item = window.localStorage.getItem(key);
        if (item) {
          return JSON.parse(item)
        }
      } catch (error) {
        //ignore
      }
    }

    return initialValue;
  });

  useEffect(() => {
    const eventListener = (e: StorageEvent) => {
      if(key != null) {
        try {
          const item = window.localStorage.getItem(key);
          if (item) {
            setStoredValue(JSON.parse(item))
          }
        } catch (error) {
          // ignored
        }
      }
    }

    window.addEventListener('storage', eventListener)

    return () => window.removeEventListener('storage', eventListener)
  }, [key, setStoredValue])

  const setValue = useCallback((value: T|((prevState: T) => T)) => {
    try {
      const oldValue = storedValue;
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if(key != null) {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
      // dispatch storage event manually, so it is reached by other useLocalStorage hooks in the same tab (and different tabs too)
      window.dispatchEvent(new StorageEvent('storage', {
        key: key,
        newValue: JSON.stringify(valueToStore),
        oldValue: JSON.stringify(oldValue),
        storageArea: window.localStorage,
        url: window.location.href,
      }))
    } catch (error) {
      //errorHandler(error);
    }
  }, [key, storedValue, setStoredValue]);
  return [storedValue, setValue];
}

