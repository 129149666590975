import {
  IAbstractScoreboard,
  IBasicGameMetaInfo,
  IBasicScoreInfo, IEvent,
  IGame, IGameShowResponse, ISportEventContentBlock,
  ITitleGameMetaInfo
} from "@webng-types/write-model";
import {maybeLegacyEventInfoToContentsConverter} from "./maybeLegacyEventInfoToContentsConverter";
import {
  legacySportEventInfoConverter,
  SetBasedScoreboardDisplayTypes,
  TeamGameScoreboardDisplayTypes
} from "@webng/sports";

function maybeConvertMetaInfo(metaInfo: IBasicGameMetaInfo|undefined): ITitleGameMetaInfo {
  if(metaInfo) {
    switch (metaInfo._type) {
      case "Tik::Model::GameMetaInfo::TitleGameMetaInfo":
        return metaInfo;
      case "Tik::Model::GameMetaInfo::ConferenceGameMetaInfo":
        return {
          ...metaInfo,
          _type: "Tik::Model::GameMetaInfo::TitleGameMetaInfo"
        }
      case "Tik::Model::GameMetaInfo::TeamGameMetaInfo":
        return {
          ...metaInfo,
          title: metaInfo.home_team?.name + " vs. " + metaInfo.away_team?.name,
          _type: "Tik::Model::GameMetaInfo::TitleGameMetaInfo"
        }
      case "Tik::Model::GameMetaInfo::BasicGameMetaInfo":
        return {
          ...metaInfo,
          title: "untitled",
          _type: "Tik::Model::GameMetaInfo::TitleGameMetaInfo"
        };
      default:
        throw new Error(`Game with unknown MetaInfo ${metaInfo._type}`)
    }
  } else {
    throw new Error("Game without MetaInfo")
  }
}

function maybeConvertScoreboard(metaInfo: IBasicGameMetaInfo|undefined, scoreInfo: IBasicScoreInfo|undefined): IAbstractScoreboard|undefined {
  if(metaInfo?._type === 'Tik::Model::GameMetaInfo::TeamGameMetaInfo') {
    if(scoreInfo?._type === "Tik::Model::ScoreInfo::TeamGameScoreInfo") {
      return {
        _type: "Tik::Model::Content::Scoreboard::TeamGameScoreboard",
        display_type: TeamGameScoreboardDisplayTypes.DEFAULT,
        home_team: metaInfo.home_team,
        away_team: metaInfo.away_team,
        score: {
          _type: "Tik::Model::Content::Scoreboard::ScoreboardScore",
          home_score: scoreInfo.home_score !== undefined ? scoreInfo.home_score.toString() : undefined,
          away_score: scoreInfo.away_score !== undefined ? scoreInfo.away_score.toString() : undefined,
        }
      }
    } else if(scoreInfo?._type === "Tik::Model::ScoreInfo::SetBasedScoreInfo") {
      return {
        _type: "Tik::Model::Content::Scoreboard::SetBasedScoreboard",
        display_type: SetBasedScoreboardDisplayTypes.DEFAULT_ONLY_SCORES,
        home_team: metaInfo.home_team,
        away_team: metaInfo.away_team,
        scores: scoreInfo.set_scores?.map((setScore) => ({
          _type: "Tik::Model::Content::Scoreboard::ScoreboardScore",
          home_score: setScore.home_score !== undefined ? setScore.home_score.toString() : undefined,
          away_score: setScore.away_score !== undefined ? setScore.away_score.toString() : undefined
        })) || []
      }
    }
  }

  return undefined;
}

export async function maybeLegacySportEventInfoConverter(game: IGame, event: IEvent): Promise<ISportEventContentBlock|undefined> {
  if(game.sportstype !== 'news' && game.sportstype !== 'blog' && game.sportstype !== 'generic' && !event.sport_event) {
    return await legacySportEventInfoConverter(game, event)
  } else {
    return event.sport_event
  }
}

async function legacyEventListConverter(game: IGame, events: IEvent[]|undefined): Promise<IEvent[]|undefined> {
  if(events && events.length > 0) {
    const eventsPromises: Promise<IEvent>[] = events.map(async (event): Promise<IEvent> => ({
      ...event,
      contents: maybeLegacyEventInfoToContentsConverter(event),
      sport_event: await maybeLegacySportEventInfoConverter(game, event)
    })) || []

    return Promise.all<IEvent>(eventsPromises)
  } else {
    return undefined
  }
}

export async function legacyGameConverter(game: IGame): Promise<IGame> {
  return {
    ...game,
    meta_info: maybeConvertMetaInfo(game.meta_info),
    score_info: undefined,
    scoreboard: game.scoreboard || maybeConvertScoreboard(game.meta_info, game.score_info),
    events: await legacyEventListConverter(game, game.events),
  }
}

export async function legacyGameShowResponseConverter(response: IGameShowResponse): Promise<IGameShowResponse> {
  return {
    _type: "Tik::Model::Api::GameShowResponse",
    game: response.game ? await legacyGameConverter(response.game) : undefined,
    events: await legacyEventListConverter(response.game!, response.events)
  }
}
