import {IAbstractScoreboard, IGaelicScoreboard, ISetBasedScoreboard, ITeamGameScoreboard} from "@webng-types/write-model/index";
import {TeamGameScoreboard} from "./TeamGameScoreboard";
import React from "react";
import SetBasedScoreboard from "./SetBasedScoreboard";
import { GaelicGameScoreboard } from "./GaelicGameScoreboard";

export default function Scoreboard({scoreboard, isMain = false}: {scoreboard: IAbstractScoreboard, isMain?: boolean }) {
  switch (scoreboard._type) {
    case "Tik::Model::Content::Scoreboard::TeamGameScoreboard":
      return <TeamGameScoreboard scoreboard={scoreboard as ITeamGameScoreboard} isMain={isMain} />
    case "Tik::Model::Content::Scoreboard::SetBasedScoreboard":
      return <SetBasedScoreboard scoreboard={scoreboard as ISetBasedScoreboard} isMain={isMain} />
    case "Tik::Model::Content::Scoreboard::GaelicScoreboard":
      return <GaelicGameScoreboard scoreboard={scoreboard as IGaelicScoreboard} isMain={isMain} />
    default:
      throw new Error("Unknown Scoreboard type " + scoreboard?._type);
  }
}


