import React from "react";

interface LoadErrorViewProps {
  status: number
}

export function LoadErrorView({status}: LoadErrorViewProps){
  let headline, description;

  // this is developer info and doesn't need  to be translated IMO
  switch(status) {
    case 400:
      headline = "Malformed Parameter"
      description = "Make sure to use the correct id (liveblog id or theme id)"
      break;
    case 401:
      headline = "Authentication required"
      description = "Make sure to use the correct client_id and that your domain is enabled in the Live Blog settings under Integration"
      break;
    case 402:
      headline = "Liveblog not published"
      description = "Upgrade or subscribe to a plan including this template or publish this liveblog with Live Blog On Demand"
      break;
    case 403:
      headline = "Permission required"
      description = "Check if you have the necessary rights for this liveblog and if you used the correct client_id and id (embed_id)"
      break;
    case 404:
      headline = "Liveblog not found"
      description = "Make sure to use the correct id (liveblog id or theme id)"
      break;
    case 500:
    case 501:
    case 502:
    case 503:
    case 504:
      headline = "Unexpected Error"
      description = "Please try again later"
      break;
    default:
      headline = "Unable to load this liveblog"
      description = "Check your internet connection and try again"
      break;
  }

  return <div className="tik4-load-error tik4 tik4--on-auto">
    <div className="tik4-load-error__container">
      <div className="tik4-load-error__code">{status}</div>
      <div className="tik4-load-error__content">
        <div className="tik4-load-error__content__headline">{headline}</div>
        <div className="tik4-load-error__content__description">{description}</div>
      </div>
    </div>
  </div>
}
