import React from "react";
import {IEvent, IGame, IMultiMediaContentBlock} from "@webng-types/write-model";
import { OnMediaClickHandler } from "./MultiMediaContentBlockView";
import {ChevronRight, ChevronLeft} from "react-feather";
import { MediaItemView } from "./MediaItemVIew";
import { MediaItemViewModel } from "../converter/ViewModel";
import { SliderWrapper } from "../ReactSlickLazy";

interface MultiMediaSliderProps {
  items: Array<MediaItemViewModel>
  game: IGame
  event: IEvent
  block: IMultiMediaContentBlock
  onMediaClick?: OnMediaClickHandler
}

interface SlickArrow {
  className?: string
  onClick?: () => void
}

export function SlickNextArrow({className, onClick}: SlickArrow) {
  return (
    <button
      className={className}
      onClick={onClick}
    >
      <div className="tik4-sl__a">
        <ChevronRight className="tik4-sl__a__s" />
      </div>
    </button>
  );
}

export function SlickPrevArrow({className, onClick}: SlickArrow) {
  return (
    <button
      className={className}
      onClick={onClick}
    >
      <div className="tik4-sl__a">
        <ChevronLeft className="tik4-sl__a__s" />
      </div>
  </button>
  );
}

export function MultiMediaSlider({items, game, event, block, onMediaClick} : MultiMediaSliderProps) {

    const settings = {
        infinite: true,
        speed: 150,
        slidesToShow: 1,
        slidesToScroll: 1,
        className:"tik4-sl",
        nextArrow: <SlickNextArrow />,
        prevArrow: <SlickPrevArrow />,
        dots: true,
        appendDots: (dots:React.ReactNode) => <ul className="tik4-sl__d">{dots}</ul>,
        dotsClass: "tik4-sl__d",
        customPaging: (i:number) => (
          <button className="tik4-sl__d__d">
            <div className="tik4-sl__d__d__i">{i+1}</div>
          </button>
        )
      };

    // TODO: After I added @webng-types/embedjs to package.json I got couple of error. "GALLERY" is temporary
      return <div className={`tik4-media-list`} style={{maxWidth: '100%'}}>
          <SliderWrapper {...settings}>
            {items.map(item => <React.Fragment key={item.id}>
              <MediaItemView gameId={game.local_id!} eventId={event.local_id!} item={item} onMediaClick={onMediaClick} />
            </React.Fragment>)}
          </SliderWrapper>
      </div>
  }
