import React, {useCallback} from "react";

// import {Star} from "react-feather";
import {IEvent} from "@webng-types/write-model";
import {getHighlightPostText} from "./utils/eventUtils";
import {OnSummaryItemClickHandler} from "./Liveblog";

interface LiveblogHighlightSummaryItemProps {
  event: IEvent
  onClick?: OnSummaryItemClickHandler
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void
}

export const LiveblogHighlightSummaryItem = React.forwardRef<HTMLHeadingElement, LiveblogHighlightSummaryItemProps>(function LiveblogHighlightSummaryItemImp({event, onClick, onKeyDown}, ref) {
  const onClickCallback = useCallback(() => {
    onClick && onClick(event)
  }, [onClick, event])

  const content = getHighlightPostText(event);

  if (!content) {
    return <></>;
  }

  return <div className="tik4-hs__item" role="listitem">
    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */}
    <h3 className="tik4-hs__title" onClick={onClickCallback} onKeyDown={onKeyDown} tabIndex={0} role="button" ref={ref}>
      {/* <Hexagon className="tik4-chapter-icon tik4-hs__title__icon" /> */}
      {/* <Star className="tik4-chapter-icon tik4-hs__title__icon" /> */}
      <div className="tik4-hs__title__content">{ getHighlightPostText(event) }</div>
    </h3>
  </div>
})
