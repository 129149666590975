import React, {useCallback} from "react";
import {EmbedProviderProps} from "./EmbedProviders";
import {ensureScriptTag} from "./tagManager";

export function Twitter({oembed}: EmbedProviderProps) {

  const ref = useCallback((element: HTMLDivElement|null) => {
    if(element) {
      if (typeof (window as any).twttr !== 'undefined' && typeof (window as any).twttr.widgets !== 'undefined') {
        (window as any).twttr.widgets.load(element);
      } else {
        ensureScriptTag({
          src: 'https://platform.twitter.com/widgets.js',
          async: true,
          defer: true,
          crossOrigin: "anonymous",
          referrerPolicy: "no-referrer"
        });
        // adapted from https://developer.twitter.com/en/docs/twitter-for-websites/javascript-api/guides/set-up-twitter-for-websites
        const t = (window as any).twttr || {}
        if (typeof t.ready === 'undefined') {
          t._e = []
          t.ready = function (f: any) {
            this._e.push(f);
          };
          (window as any).twttr = t
        }

        t.ready(function () {
          (window as any).twttr.widgets.load(element);
        })
      }
    }
  }, [])

  return <div className="tik4-we-twitter" ref={ref} dangerouslySetInnerHTML={{__html: oembed.html || ""}} />
}

export default Twitter
