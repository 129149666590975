import {useMemo} from "react";
import {ApiClient, AuthenticatedApiClient} from "@webng/tickaroo-api";
import {useEnvironment} from "./Environment";
import {fetchFn} from "./CommonComponents";

function useApiClient(clientId: string, host: string, invalidateAccessToken: () => void): ApiClient {
  return useMemo(() => {
    return new ApiClient({
      fetchFn: fetchFn,
      clientId: clientId,
      host: host,
      interpretResponse: true,
      invalidateCallback: invalidateAccessToken
    })
  }, [clientId, host, invalidateAccessToken])
}

export function useApi(): ApiClient {
  const env = useEnvironment();

  return useApiClient(env.clientId, env.apiHost, env.invalidateAccessToken);
}

export function useAuthenticatedApi(): AuthenticatedApiClient {
  const env = useEnvironment();

  return useAuthenticatedApiWithHost(env.apiHost)
}

export function useAuthenticatedApiWithHost(host: string): AuthenticatedApiClient {
  const env = useEnvironment();

  const apiClient = useApiClient(env.clientId, host, env.invalidateAccessToken);

  return useMemo(() => {
    return new AuthenticatedApiClient(apiClient, env.accessToken)
  }, [apiClient, env.accessToken])
}

