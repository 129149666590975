import generateUniqueId from './generateUniqueId';
import {get, set} from './storage'

const cookieName = 'tickaroo-tika';

let _uid = get(cookieName);

function uid(useCookie: boolean) {
  if(useCookie) {
    if (!_uid) {
      _uid = generateUniqueId();
      set(cookieName, _uid);
    }

    return _uid
  } else {
    return null
  }
}

const sid = generateUniqueId()
const referrer = document.referrer ? document.referrer.substring(0, 2048) : "";

export default function globals(useCookie: boolean) {
  return {
    uid: uid(useCookie),
    sid,
    uaLocation: window.location.href.substring(0, 2048 ),
    uaReferrer: referrer
  }
}
