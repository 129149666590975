import {useCallback, useRef, useState} from 'react';
import useResizeObserver from "use-resize-observer";
import {useDebouncedFunction} from "./debounce";

type ObservedSize = {
  width: number | undefined;
  height: number | undefined;
};

export default function useDebouncedResizeObserver(wait: number): {ref: any, width: number|undefined, height: number|undefined }{
  const ref = useRef<HTMLDivElement|null>(null);
  const [size, setSize] = useState<ObservedSize>({width: undefined, height: undefined});
  const resizeObserverAvailable = !!(typeof window !== 'undefined' && window.ResizeObserver)

  const onResize = useDebouncedFunction(setSize, wait)
  useResizeObserver({ onResize, ref: resizeObserverAvailable ? ref : null });

  const setRef = useCallback((elem: HTMLDivElement|null) => {
    ref.current = elem;
    if(elem){
      setSize({width: elem.offsetWidth, height: elem.offsetHeight});
    }
  }, [setSize]);

  return { ref: setRef, ...size };
}
