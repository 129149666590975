import {
  LiveblogCoreApi,
  LiveblogCoreWidgetProps,
  LiveblogCoreWidgetQueryPropsParser,
  LiveblogCoreWidgetTagProps,
  liveblogCoreWidgetTagPropsParser,
  NavigateToEventIdOptions,
} from "./liveblog-core/LiveblogCoreWidgetProps";
import {LiveblogCoreWidget,} from "./liveblog-core/LiveblogCoreWidget";
import {parseQueryProps, parseTagProps} from "../utils/propsParser";
import {LiveblogCoreWidgetInitialData} from "@webng-types/embedjs";
import {parseJSON, PropsParser} from "@webng/validations";
import {AdManager} from "../ads/AdManager";
import {domContentLoaded} from "../utils/domContentLoaded";
import {notifyError} from "../analytics/notifyError";
import ReactDOM from "react-dom/client";
import React from "react";

interface CustomElementIntegrationProps {
  initialData: LiveblogCoreWidgetInitialData;
}

const propsParser: PropsParser<
  LiveblogCoreWidgetTagProps & CustomElementIntegrationProps
> = {
  ...liveblogCoreWidgetTagPropsParser,
  initialData: (i) => parseJSON("initialData", i, {}),
};

export class TickarooLiveblogCoreElement
  extends HTMLElement
  implements LiveblogCoreApi
{
  private readonly wrapper: HTMLElement;
  private props?: LiveblogCoreWidgetProps & CustomElementIntegrationProps;
  private isRendered = false;
  private root: ReactDOM.Root|undefined

  static get observedAttributes() {
    return Object.keys(propsParser).map((x) => x.toLowerCase());
  }

  constructor() {
    super();
    this.wrapper = this;
  }

  connectedCallback() {
    this.props = this.parseProps();
    if (this.props) {
      if (this.props.initialData.gameShowResponse) {
        this.hydrateWidget(this.wrapper, this.props);
      } else {
        this.renderWidget(this.wrapper, this.props);
      }
      this.isRendered = true;
    }
  }

  disconnectedCallback() {
    this.isRendered = false;
    this.root?.unmount()
    this.root = undefined
  }

  attributeChangedCallback(
    name: string,
    oldValue: string | null,
    newValue: string | null
  ) {
    if (this.isRendered) {
      this.props = this.parseProps();
      this.renderWidget(this.wrapper, this.props);
    }
  }

  parseProps() {
    const tagProps = parseTagProps(propsParser, this);
    const queryProps = parseQueryProps(LiveblogCoreWidgetQueryPropsParser);
    return Object.assign({}, tagProps, queryProps);
  }

  hasEventIdLoaded(eventId: string): boolean {
    throw new Error("Liveblog not initialized");
  }

  loadMoreBottom(): PromiseLike<void> {
    throw new Error("Liveblog not initialized");
  }

  loadMoreTop(): PromiseLike<void> {
    throw new Error("Liveblog not initialized");
  }

  navigateToEventId(eventId: string, options?: NavigateToEventIdOptions): PromiseLike<boolean> {
    throw new Error("Liveblog not initialized");
  }

  setConsent(promise: string, consent: boolean): void {
    throw new Error("Liveblog not initialized");
  }

  setAdManager(newAdManager: AdManager): void {
    throw new Error("Liveblog not initialized");
  }

  renderWidget(el: Element, props: LiveblogCoreWidgetProps) {
    if(!this.root) {
      this.root = ReactDOM.createRoot(el, {
        onRecoverableError: (error: unknown, errorInfo: ReactDOM.ErrorInfo) => {
          notifyError(error, {
            digest: errorInfo.digest || "",
            componentStack: errorInfo.componentStack || ""
          })
        }
      })
    }
    this.root.render(<LiveblogCoreWidget {...props} />)
  }

  hydrateWidget(el: Element, props: LiveblogCoreWidgetProps) {
    domContentLoaded(() => ReactDOM.hydrateRoot(el, <LiveblogCoreWidget {...props} />))
  }

}
