import * as React from "react";
import type { SVGProps } from "react";
const BlackCard = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd" transform="translate(6 3.5)">
      <rect
        width={13.85}
        height={18.85}
        x={-0.93}
        y={-0.93}
        fill="#787878"
        stroke="#FFF"
        strokeWidth={1.85}
        rx={1.2}
      />
      <rect
        width={13.1}
        height={18.1}
        x={-0.55}
        y={-0.55}
        stroke="#000"
        strokeWidth={1.1}
        rx={1.2}
      />
    </g>
  </svg>
);
export default BlackCard;
