import {
  LiveblogWidget
} from "./liveblog/LiveblogWidget";
import {
  parsePartialTagProps,
  parseQueryProps,
  parseTagProps,
} from "../utils/propsParser";
import { PropsParser } from "@webng/validations";
import {
  LiveblogApi,
  LiveblogWidgetProps,
  LiveblogWidgetQueryPropsParser,
  LiveblogWidgetTagProps,
  liveblogWidgetTagPropsParser,
} from "./liveblog/LiveblogWidgetProps";
import { liveblogThemeConfigurationPropsParser } from "@webng-types/embedjs";
import { notInitialized } from "../utils/notInitialized";
import globalSettings from "./globalSettings";
import {AdManager} from "../ads/AdManager";
import {LiveblogCoreApi, NavigateToEventIdOptions} from "./liveblog-core/LiveblogCoreWidgetProps";
import ReactDOM from "react-dom/client";
import {notifyError} from "../analytics/notifyError";
import React from "react";
import {domContentLoaded} from "../utils/domContentLoaded";

const propsParser: PropsParser<LiveblogWidgetTagProps> = {
  ...liveblogWidgetTagPropsParser,
};

export class TickarooLiveblogElement
  extends HTMLElement
  implements LiveblogApi
{
  private readonly wrapper: HTMLElement;
  private props?: LiveblogWidgetProps;
  private isRendered = false;
  private rerenderAfterHydrate = false
  private liveblogComponent: LiveblogCoreApi|undefined;
  private root: ReactDOM.Root|undefined

  static get observedAttributes() {
    return [
      ...Object.keys(propsParser).map((x) => x.toLowerCase()),
      ...Object.keys(liveblogThemeConfigurationPropsParser).map((x) =>
        x.toLowerCase()
      ),
    ];
  }

  constructor() {
    super();
    this.wrapper = this;
  }

  connectedCallback() {
    this.props = this.parseProps();
    if (this.props) {
      if (globalSettings.useHydrate && this.props.initialData.configuration) {
        this.hydrateWidget(this.wrapper, this.props, (ref) => this.liveblogComponent = ref);
      } else {
        this.renderWidget(this.wrapper, this.props, (ref) => this.liveblogComponent = ref);
      }
    }
  }

  disconnectedCallback() {
    this.isRendered = false;
    this.liveblogComponent = undefined;
    this.root?.unmount()
    this.root = undefined
  }

  attributeChangedCallback(
    name: string,
    oldValue: string | null,
    newValue: string | null
  ) {
    if (this.isRendered && !this.rerenderAfterHydrate) {
      this.props = this.parseProps();
      this.renderWidget(this.wrapper, this.props, (ref) => this.liveblogComponent = ref);
    } else {
      this.rerenderAfterHydrate = true
    }
  }

  parseProps() {
    const tagProps = parseTagProps(propsParser, this);
    const overrideConfigurationProps = parsePartialTagProps(
      liveblogThemeConfigurationPropsParser,
      this
    );
    const queryProps = parseQueryProps(LiveblogWidgetQueryPropsParser);
    return Object.assign({}, tagProps, queryProps, {
      overrideConfiguration: overrideConfigurationProps,
    });
  }

  hasEventIdLoaded(eventId: string): boolean {
    if (this.liveblogComponent) {
      return this.liveblogComponent.hasEventIdLoaded(eventId);
    } else {
      return notInitialized();
    }
  }

  loadMoreBottom(): PromiseLike<void> {
    if (this.liveblogComponent) {
      return this.liveblogComponent.loadMoreBottom();
    } else {
      return notInitialized();
    }
  }

  loadMoreTop(): PromiseLike<void> {
    if (this.liveblogComponent) {
      return this.liveblogComponent.loadMoreTop();
    } else {
      return notInitialized();
    }
  }

  setConsent(provider: string, consent: boolean): void {
    if (this.liveblogComponent) {
      return this.liveblogComponent.setConsent(provider, consent);
    } else {
      return notInitialized();
    }
  }

  setAdManager(newAdManager: AdManager): void {
    if (this.liveblogComponent) {
      return this.liveblogComponent.setAdManager(newAdManager)
    } else {
      return notInitialized()
    }
  }

  navigateToEventId(eventId: string, options?: NavigateToEventIdOptions): PromiseLike<boolean> {
    if (this.liveblogComponent) {
      return this.liveblogComponent.navigateToEventId(eventId, options);
    } else {
      return notInitialized();
    }
  }

  renderWidget(el: Element, props: LiveblogWidgetProps, refCallback: (ref: LiveblogCoreApi) => void) {
    if(!this.root) {
      this.root = ReactDOM.createRoot(el, {
        onRecoverableError: (error: unknown, errorInfo: ReactDOM.ErrorInfo) => {
          notifyError(error, {
            digest: errorInfo.digest || "",
            componentStack: errorInfo.componentStack || ""
          })
        }
      })
    }
    this.root.render(<LiveblogWidget ref={refCallback} {...props} />)
    this.isRendered = true;
    this.rerenderAfterHydrate = false;
  }

  hydrateWidget(el: Element, props: LiveblogWidgetProps, refCallback: (ref: LiveblogCoreApi) => void) {
    return domContentLoaded(() => {
      const refCallbackWrapper = (ref: LiveblogCoreApi) => {
        if(this.rerenderAfterHydrate) {
          setTimeout(() => {
            this.props = this.parseProps();
            this.renderWidget(this.wrapper, this.props, (ref) => this.liveblogComponent = ref);
          }, 100)
        } else {
          this.isRendered = true;
        }

        refCallback(ref)
      }

      this.root = ReactDOM.hydrateRoot(el, <LiveblogWidget ref={refCallbackWrapper} {...props} />, {
        onRecoverableError: (error: unknown, errorInfo: ReactDOM.ErrorInfo) => {
          notifyError(error, {
            digest: errorInfo.digest || "",
            componentStack: errorInfo.componentStack || ""
          })
        }
      })
    })
  }
}
