import globalSettings from "../tik4/globalSettings";

type CssNames = "tik4.css"

function findClientStyleTag(name: CssNames) {
  const tags = document.getElementsByTagName("link");
  for(let i = 0; i < tags.length; ++i) {
    const tag = tags[i];
    if(tag.rel === 'stylesheet' && tag.href) {
      var match = tag.href.includes(name);
      if(match) {
        return tag;
      }
    }
  }
  return null;
}

export function createClientStyleTag(name: CssNames) {
  const style = document.createElement('link');
  style.href = globalSettings.scriptBaseUrl + '/' + name;
  style.type = "text/css";
  style.rel = "stylesheet";
  return style
}


export function ensureCss(name: CssNames) {
  if(!findClientStyleTag(name)) {
    const style = createClientStyleTag(name);
    document.head.appendChild(style);
  }
}
