import React, {useCallback} from "react";

import {Hexagon} from "react-feather";
import {IEvent} from "@webng-types/write-model";
import {OnSummaryItemClickHandler} from "./Liveblog";

interface LiveblogMilestoneListItemProps {
  event: IEvent
  onClick?: OnSummaryItemClickHandler
  onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void
}

export const LiveblogMilestoneListItem = React.forwardRef<HTMLHeadingElement, LiveblogMilestoneListItemProps>(function LiveblogMilestoneListItemImp({event, onClick, onKeyDown}, ref) {
  const onClickCallback = useCallback(() => {
    onClick && onClick(event)
  }, [onClick, event])

  return <div className="tik4-chapters__item">
    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role */}
    <h3 className="tik4-chapters__title" onClick={onClickCallback} onKeyDown={onKeyDown} tabIndex={0} role="button" ref={ref}>
      <Hexagon className="tik4-chapter-icon tik4-chapters__title__icon" />
      <div className="tik4-chapters__title__content">{ event.headline }</div>
    </h3>
  </div>
})
