import {IGame, ISportEventContentBlock} from "@webng-types/write-model";
import {useSportEventSpec} from "../utils/useSportEventSpec";
import React from "react";

import {SportEventContentViewPlayers} from "./components/PlayerView";

interface SportEventContentViewProps {
  block: ISportEventContentBlock
  game: IGame
}

export function SportEventContentView({block, game}: SportEventContentViewProps) {
  const {sportstypeEventSpec} = useSportEventSpec(block.event_type)

  if (sportstypeEventSpec) {
    return <div className="tik4-se__c">

        { (block.title || block.team) &&
          <div className="tik4-se__m">
            { block.title &&
              <div className="tik4-se__m__ti">
                { `${block.title} `}
              </div>
            }
            { block.team &&
              <div className="tik4-se__m__te">
                { block.team.name }
              </div>
            }
          </div>
        }
        {sportstypeEventSpec && sportstypeEventSpec.players
          ?.map(p => p.id)
          .reduce((acc: string[], value) => acc.includes(value) ? acc : [...acc, value], [])
          .map(id => {
            const eventPlayers = block.players?.filter(p => p.event_player_type === id)
            if(eventPlayers && eventPlayers.length > 0) {
              return <SportEventContentViewPlayers key={id} eventPlayers={eventPlayers}  />
            } else {
              return null
            }
          })
        }
    </div>
    ;
  } else {
    return <React.Fragment/>
  }
}
