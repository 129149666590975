import { sendError } from "./sendError";
import {
  isChunkLoadError,
  isClientIntegrationError,
  isDOMError,
  isWidgetNotInitialized,
  isApiResponseError,
  isFailedToFetch
} from "./bugsnagExceptions";
import { DebugEventMetadata } from "@webng/react-app-common";

let debugEvents: Array<{
  ts: number;
  title: string;
  metaData: DebugEventMetadata;
}> = [];

export function debugEvent(title: string, metaData: DebugEventMetadata) {
  debugEvents.unshift({
    ts: new Date().getTime(),
    title: title,
    metaData: metaData,
  });
  debugEvents = debugEvents.slice(0, 10);
}

export function notifyError(e: any, metaData?: Record<string, string>) {
  console.error(e);

  if (
    isDOMError(e) ||
    isWidgetNotInitialized(e) ||
    isChunkLoadError(e) ||
    isClientIntegrationError(e) ||
    isApiResponseError(e) ||
    isFailedToFetch(e)
  ) {
    console.log('prevented error from being sent to bugsnag')
    return;
  }

  sendError(e, { debugEvents: [...debugEvents].reverse(), metaData: metaData });
}
