import {parsePartialProps, parseProps, PropsParser} from "@webng/validations";


export function parseTagProps<T>(parser: PropsParser<T>, el: Element) {
  return parseProps(parser, k => el.getAttribute(k as string))
}

export function parsePartialTagProps<T>(parser: PropsParser<T>, el: Element) {
  return parsePartialProps(parser, k => el.getAttribute(k as string))
}

const domPrefix = "tik4"
export function dataAttributeName(name: string|number|symbol) {
  return `data-${domPrefix}-${String(name)}`
}

export function parseDataTagProps<T>(parser: PropsParser<T>, el: Element) {
  return parseProps(parser, k => el.getAttribute(dataAttributeName(k)))
}


function queryAttributeName(name: string) {
  const snakeCaseName = name.replace(/([A-Z])/g, '_$1').toLowerCase();
  return `tickaroo_${snakeCaseName}`
}

export function parseQueryProps<T>(parser: PropsParser<T>) {
  // const searchParams = window.location.search ? new URLSearchParams(window.location.search) : new URLSearchParams({})
  const hashParams = window.location.hash ? new URLSearchParams(window.location.hash.substr(1)) : new URLSearchParams({})

  return parseProps(parser, k => hashParams.get(queryAttributeName(k as string)))
}

export function parsePartialQueryProps<T>(parser: PropsParser<T>) {
  // const searchParams = window.location.search ? new URLSearchParams(window.location.search) : new URLSearchParams({})
  const hashParams = window.location.hash ? new URLSearchParams(window.location.hash.substr(1)) : new URLSearchParams({})

  return parsePartialProps(parser, k => hashParams.get(queryAttributeName(k as string)))
}

export function parseDefaultProps<T>(parser: PropsParser<T>) {
  return parseProps(parser, k => null)
}
