function trySendBeacon(endpoint: string, data: string) {
  if(window.navigator.sendBeacon) {
    var blob = new Blob([data], {type: "text/plain"}); // needs to text/plain bc of http://crbug.com/490015
    return window.navigator.sendBeacon(endpoint, blob);
  } else{
    return false;
  }
}

function trySendFetch(endpoint: string, data: string) {
  // @ts-ignore
  if(window.fetch) {
    fetch(endpoint, {
      method: 'POST',
      body: data,
      headers: {
        'Content-Type': 'text/plain'
      },
      keepalive: true
    })
    return true
  } else {
    return false
  }
}

function trySendXHR(endpoint: string, data: string) {
  const client = new XMLHttpRequest();
  client.open("POST", endpoint, true); // third parameter indicates async xhr
  client.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  client.send(data);
}

export function sendEvents(endpoint: string, events: any) {
  const data = JSON.stringify(events)

  if (!trySendBeacon(endpoint, data)) {
    if (!trySendFetch(endpoint, data)) {
      trySendXHR(endpoint, data)
    }
  }
}
