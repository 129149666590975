import {IEvent} from "@webng-types/write-model";
import {AbstractAdManager} from "./AbstractAdManager";


export class ScheduledAdManager extends AbstractAdManager {

  private _start: number
  private _interval: number
  private _html: string|undefined
  private _callback: ((index: number) => string)|undefined

  constructor(start: number, interval: number, htmlOrCallback: string|((index: number) => string)){
    super()
    this._start = start
    this._interval = interval
    if(typeof htmlOrCallback === 'function') {
      this._callback = htmlOrCallback;
    } else {
      this._html = htmlOrCallback;
    }
  }

  update(rows: IEvent[]) {
    let adIndex = 0;
    for(let i = 0; i < rows.length; ++i) {
      if(i >= this._start && (i - this._start) % this._interval === 0) {
        this.notify(rows[i], this._callback ? this._callback(adIndex) : this._html)
        adIndex++;
      } else {
        this.notify(rows[i], undefined)
      }
    }
  }
}


