import React, {useMemo} from "react";
import {IEvent, IGame, IMedia, IMultiMediaContentBlock} from "@webng-types/write-model";
import {liveblogMediaItemConverter} from "../converter/liveblogMediaItemConverter";
import { MediaItemView } from "./MediaItemVIew";
import { MediaItemViewModel } from "../converter/ViewModel";
import {MultiMediaSlider} from "./MultiMediaSlider";
// TODO:Matthias not working wit SSR?
// const MultiMediaSlider = React.lazy(() => import("./MultiMediaSlider").then(({ MultiMediaSlider }) => ({ default: MultiMediaSlider as ComponentType<any> })),)

export type OnMediaClickHandler = (media: IMedia) => void

export interface MultiMediaContentBlockViewExportedProps {
  onMediaClick?: OnMediaClickHandler
}

interface MultiMediaContentBlockViewProps extends MultiMediaContentBlockViewExportedProps{
  game: IGame
  event: IEvent
  block: IMultiMediaContentBlock
}

export function MultiMediaContentBlockView({game, event, block, onMediaClick} : MultiMediaContentBlockViewProps) {

  const items:Array<MediaItemViewModel> = useMemo(() => {
    return block.items?.map(item => liveblogMediaItemConverter(event, block, item)) || []
  }, [event, block])


  // TODO:Matthias After I added @webng-types/embedjs to package.json I got couple of error. "GALLERY" is temporary
  if(block.display_type == "slideshow" && block.items && block.items.length > 1){
    return <div className={`tik4-media-list`}>
      {/*<Suspense fallback={<span>Loading... </span>}>*/}
        <MultiMediaSlider
          items={items}
          game={game}
          event={event}
          block={block}
          onMediaClick={onMediaClick}
        />
      {/*</Suspense>*/}
    </div>
  }

  return <div className={`tik4-media-list ${ block.items ? `tik4-media-list--length-${block.items.length}` : ''}`}>
    {items.map(item => <React.Fragment key={item.id}>
      <MediaItemView gameId={game.local_id!} eventId={event.local_id!} item={item} onMediaClick={onMediaClick} />
    </React.Fragment>)}
  </div>

}
